import { NavigationGuard } from 'vue-router';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { AccountStates } from '@/modules/common/services/http/account';

function waitForStateInitialization(): Promise<void> {
  return new Promise<void>(resolve => {
    const checkState = () => {
      if (CustomerModule.getAccountStatus) {
        resolve();
      } else {
        setTimeout(checkState, 100);
      }
    };
    checkState();
  });
}

export const clientEnabled: NavigationGuard = async (to, _from, next) => {
  const isPublic = to.matched.some(
    record => record.meta.requiresAuth === false
  );
  if (isPublic) {
    next();
    return;
  }

  try {
    await waitForStateInitialization();
    const accountStatus = CustomerModule.getAccountStatus as AccountStates;
    const isCustomerPJ = CustomerModule.isCustomerPJ;

    const { clientEnabled: isClientEnabled, hasInvestmentProfile } =
      accountStatus;
    const pathIncludesMainTabs =
      to.path.includes('wealth') ||
      to.path.includes('trade') ||
      to.path.includes('products-view');

    if ((isClientEnabled && hasInvestmentProfile) || isCustomerPJ) {
      next();
      return;
    } else if (
      (!isClientEnabled || !hasInvestmentProfile) &&
      pathIncludesMainTabs
    ) {
      next({ name: 'home' });
      return;
    }

    next();
  } catch (error) {
    console.error(error);
  }
};
