import { render, staticRenderFns } from "./ProductSideSheetActionBar.vue?vue&type=template&id=1b2a3f57&scoped=true&"
import script from "./ProductSideSheetActionBar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ProductSideSheetActionBar.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ProductSideSheetActionBar.vue?vue&type=style&index=0&id=1b2a3f57&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b2a3f57",
  null
  
)

export default component.exports