import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import store from '@/store';

import {
  getBalances,
  IHeroBalances,
  AccountStatus,
  HomeBrokerStatus,
  getAccountStatus,
  getDashStatus,
  IDashButtons,
  IDashStatusCard
} from '@/modules/trade/services/account';
import { DashStatusButtonActions } from '@/modules/trade/types/dash-status';
import { MarketData } from '@/modules/trade/services/signalr';
import {
  Instrument,
  InstrumentWithWatchlist
} from '@/modules/trade/services/instruments';
import { moneyFormat } from '@/modules/common/helpers/currency';
import { calculateProfitPrice } from './helpers';
import { TradeState, RequestStatus } from './types';
import { CustomOperationSummary } from '@/modules/trade/services/custom-operations';
import {
  trackBalancesRequestError,
  trackAccountStatusRequestError,
  trackDashStatusRequestError
} from '@/modules/trade/views/TradeView/trackers';
import { logError } from '@/modules/trade/helpers/logs';
import { NumberFormatted } from '@/modules/trade/types';
import { format } from 'date-fns';
import { TradeInstrumentPositionPrice } from '@/modules/trade/types/instrument';

@Module({ dynamic: true, namespaced: true, name: 'tradeModule', store })
export class TradeModuleClass extends VuexModule implements TradeState {
  public accountStatusRequestStatus: RequestStatus = RequestStatus.Loading;
  public tradeStatus: AccountStatus | null = null;
  public homeBrokerStatus: HomeBrokerStatus | null = null;

  public balancesBrokerRequestStatus: RequestStatus = RequestStatus.Loading;
  public balances: IHeroBalances | null = null;

  public balanceRequestStatus: RequestStatus = RequestStatus.Loading;

  public dashStatusRequestStatus: RequestStatus = RequestStatus.Loading;
  public accountButtons: IDashButtons[] = [];
  public dashStatusCard: IDashStatusCard | null = null;
  public userHasBrokerAccount: boolean | null = null;

  public positions: Instrument[] = [];
  public positionsUpdatedAt: NumberFormatted = {
    value: 0,
    formatted: '-'
  };

  public watchlist: InstrumentWithWatchlist[] = [];

  public customOperations: CustomOperationSummary[] = [];

  public get getPositions() {
    return this.positions;
  }

  public get getWatchlist() {
    return this.watchlist;
  }

  @Mutation
  public setTradeStatus(tradeStatus: AccountStatus | null): void {
    this.tradeStatus = tradeStatus;
  }

  @Mutation
  public setHomeBrokerStatus(homeBrokerStatus: HomeBrokerStatus | null): void {
    this.homeBrokerStatus = homeBrokerStatus;
  }

  @Mutation
  public setStatusRequestBroker(requestStatus: RequestStatus): void {
    this.accountStatusRequestStatus = requestStatus;
  }

  @Mutation
  public setBalancesBrokerRequestStatus(requestStatus: RequestStatus): void {
    this.balancesBrokerRequestStatus = requestStatus;
  }

  @Mutation
  public setBalanceRequestStatus(requestStatus: RequestStatus): void {
    this.balanceRequestStatus = requestStatus;
  }

  @Mutation
  public setDashStatusRequestStatus(requestStatus: RequestStatus): void {
    this.dashStatusRequestStatus = requestStatus;
  }

  @Mutation
  public setBalances(balances: IHeroBalances | null): void {
    this.balances = balances;
  }

  @Mutation
  public setAccountButtons(accountButtons: IDashButtons[]): void {
    this.accountButtons = accountButtons;
  }

  @Mutation
  public setDashStatusCard(dashStatusCard: IDashStatusCard | null): void {
    this.dashStatusCard = dashStatusCard;
  }

  @Mutation
  public setUserHasBrokerAccount(userHasBrokerAccount: boolean): void {
    this.userHasBrokerAccount = userHasBrokerAccount;
  }

  @Mutation
  public setPositionList(positionsList: Instrument[]): void {
    this.positions = positionsList;
  }

  @Mutation
  public setPositionsUpdatedAt(updatedAt: NumberFormatted): void {
    this.positionsUpdatedAt = updatedAt;
  }

  @Mutation
  public setWatchlist(watchlist: InstrumentWithWatchlist[]): void {
    this.watchlist = watchlist;
  }

  @Mutation
  updateWatchlist(data: MarketData) {
    const watchlist = (this.watchlist as InstrumentWithWatchlist[]).find(
      watchlist => watchlist.symbol === data.ticker
    );

    if (watchlist) {
      if (data.price?.formatted) watchlist.lastPrice = data.price;
      if (data.dailyVariation?.formatted)
        watchlist.dailyPercentageChange = data.dailyVariation;
    }
  }

  @Mutation
  updatePosition(data: TradeInstrumentPositionPrice) {
    const position = (this.positions as Instrument[]).find(
      position => position.symbol === data.symbol
    );

    if (position) {
      if (data.price?.formatted) position.lastPrice = data.price;
      if (data.dailyPercentualChange?.formatted)
        position.dailyPercentageChange = data.dailyPercentualChange;
      if (data.totalEarnings?.formatted)
        position.profitPercentageChange = data.totalEarnings;
      if (data.currentValue?.formatted) position.balance = data.currentValue;
      if (
        data.price?.value &&
        position.averagePrice.value &&
        position.quantity
      ) {
        const newProfitPrice = calculateProfitPrice(
          data.price.value,
          position.averagePrice.value,
          position.quantity
        );

        position.profitPrice = {
          value: newProfitPrice,
          formatted: moneyFormat(newProfitPrice)
        };
      }
      if (data.date) {
        const date = new Date(data.date);
        const newUpdatedAt = {
          value: date.getTime(),
          formatted: format(date, `'Atualizado às' HH:mm`)
        };

        if (
          newUpdatedAt.value > this.positionsUpdatedAt.value &&
          newUpdatedAt.formatted !== this.positionsUpdatedAt.formatted
        ) {
          this.positionsUpdatedAt = newUpdatedAt;
        }
      }
    }
  }

  @Mutation
  setCustomOperations(customOperations: CustomOperationSummary[]) {
    this.customOperations = customOperations;
  }

  @Action
  resetTradeHomeStates() {
    this.setAccountButtons([]);
    this.setDashStatusCard(null);
    this.setBalances(null);
    this.setPositionList([]);
    this.setPositionsUpdatedAt({
      value: 0,
      formatted: '-'
    });
    this.setWatchlist([]);
    this.setTradeStatus(null);
    this.setHomeBrokerStatus(null);
  }

  @Action
  changeUserHasBrokerAccountValue(value: boolean) {
    this.setUserHasBrokerAccount(value);
  }

  @Action
  async fetchStatus() {
    this.setStatusRequestBroker(RequestStatus.Loading);
    try {
      const data = await getAccountStatus();

      this.setStatusRequestBroker(RequestStatus.Default);

      this.setTradeStatus(data);
      this.setHomeBrokerStatus(data.homeBroker);
    } catch (error) {
      logError(error, 'request_account_status_error');
      trackAccountStatusRequestError();
      this.setStatusRequestBroker(RequestStatus.Error);
    }
  }

  @Action
  async fetchBalances() {
    this.setBalancesBrokerRequestStatus(RequestStatus.Loading);
    try {
      const balances = await getBalances();

      this.setBalancesBrokerRequestStatus(RequestStatus.Default);
      this.setBalances(balances);
    } catch (error) {
      logError(error, 'request_balance_resume_error');
      trackBalancesRequestError();
      this.setBalancesBrokerRequestStatus(RequestStatus.Error);
    }
  }

  @Action
  async fetchDashStatus() {
    this.setDashStatusRequestStatus(RequestStatus.Loading);
    try {
      const data = await getDashStatus();

      if (data?.card?.button) data.card.button = null;

      this.setDashStatusCard(data.card);
      this.setAccountButtons(data.buttons);

      this.setDashStatusRequestStatus(RequestStatus.Default);
    } catch (error) {
      logError(error, 'request_dashboard_status_error');
      trackDashStatusRequestError();
      this.setAccountButtons([
        {
          action: DashStatusButtonActions.Search,
          label: 'Negociar ativo',
          icon: 'EE0150',
          type: 'primary'
        }
      ]);
      this.setDashStatusRequestStatus(RequestStatus.Error);
    }
  }
}
