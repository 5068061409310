import { render, staticRenderFns } from "./CardCounter.vue?vue&type=template&id=aa51239c&scoped=true&"
import script from "./CardCounter.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CardCounter.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CardCounter.vue?vue&type=style&index=0&id=aa51239c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa51239c",
  null
  
)

export default component.exports