import { FadeTransition } from '@/modules/common/components/FadeTransition';
import { FadeSlideTransition } from '@/modules/common/components/FadeSlideTransition';
import { NoneTransition } from '@/modules/common/components/NoneTransition';
import { useRoute, useRouter } from 'vue-router/composables';
import { Component, ref } from 'vue';

type TransitionConfig = Record<
  string,
  typeof FadeTransition | typeof FadeSlideTransition | typeof NoneTransition
>;

export enum EAnimationComponent {
  FADE,
  FADE_SLIDE,
  NONE
}

const animationComponent = {
  [EAnimationComponent.FADE]: FadeTransition,
  [EAnimationComponent.FADE_SLIDE]: FadeSlideTransition,
  [EAnimationComponent.NONE]: NoneTransition
};

const transitionConfig: TransitionConfig = {
  '/products-view/catalog/funds': animationComponent[EAnimationComponent.NONE],
  '/products-view/catalog/fixed-income':
    animationComponent[EAnimationComponent.NONE],
  '/products-view/catalog/government-bond':
    animationComponent[EAnimationComponent.NONE],
  '/products-view/catalog/custom-operations':
    animationComponent[EAnimationComponent.NONE],
  '/catalog/builder/funds': animationComponent[EAnimationComponent.NONE],
  '/catalog/builder/fixed-income': animationComponent[EAnimationComponent.NONE],
  '/catalog/builder/government-bond':
    animationComponent[EAnimationComponent.NONE]
};

export function useRouterTransition() {
  const router = useRouter();
  const route = useRoute();
  const path = ref(route.path);
  const transitionComponent = ref<Component>(
    transitionConfig[route.path] || animationComponent[EAnimationComponent.FADE]
  );

  router.beforeEach((to, _, next) => {
    path.value = to.path;
    transitionComponent.value =
      transitionConfig[path.value] ||
      animationComponent[EAnimationComponent.FADE];
    next();
  });
  return {
    path,
    transitionComponent
  };
}
