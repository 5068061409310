import { httpClient } from '@/modules/common/services/http/http-client';
import { IQuarterlyCostsAvailable } from './types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/reports`
});

export async function getQuarterlyCostsAvailableQuarters(): Promise<
  IQuarterlyCostsAvailable[]
> {
  const response = await http.get(`/v1/quarterly-costs/quarters`);
  return response;
}

export function getQuarterlyCostsDownload(quarter: string): Promise<BlobPart> {
  return http.get(`/v1/quarterly-costs/download?quarter=${quarter}`, {
    responseType: 'blob'
  });
}
