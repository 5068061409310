import { ref } from 'vue';
import { IBankList } from '../../onboarding/services';

const bankList = ref<IBankList>();
const bankId = ref<string>();

export function useBankList() {
  const toggleBankList = (newBankList: IBankList) => {
    bankList.value = newBankList;
  };

  const toggleBankId = (id: string) => {
    bankId.value = id;
  };

  return { bankList, bankId, toggleBankList, toggleBankId };
}
