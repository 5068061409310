import { httpClient } from '@/modules/common/services/http/http-client';
import {
  ICostsDetails,
  ICostsCards,
  ICashbackDetail,
  ICostsDetailsV2,
  ICostsList
} from '@/modules/cash/services/costs';

export const bffInvestments = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export const bffPlatform = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_URL
});

export function getCostsDetailsV2(year?: string): Promise<ICostsDetailsV2> {
  if (!year) {
    return bffPlatform.get(`/portfolios/v1/costs/detail`);
  }
  return bffPlatform.get(`/portfolios/v1/costs/detail?year=${year}`);
}

export function getCashbackDetails(year?: string): Promise<ICashbackDetail> {
  if (!year) {
    return bffPlatform.get(`/portfolios/v1/costs/cashback/detail`);
  }
  return bffPlatform.get(`/portfolios/v1/costs/cashback/detail?year=${year}`);
}

export function getCostsCards(): Promise<ICostsCards> {
  return bffPlatform.get(`/portfolios/v1/costs/cards`);
}

export function getCostsList(year: string): Promise<ICostsList> {
  const currentYear = year || new Date().getFullYear();

  return bffInvestments.get(`v1/customer/getCostsList/${currentYear}`);
}

export function getCostsDetails(
  year: string,
  month: string
): Promise<ICostsDetails> {
  return bffInvestments.get(`v1/customer/getCostsDetails`, {
    params: {
      year,
      month
    }
  });
}
