import { httpClient } from '@/modules/common/services/http/http-client';
import { IFinishedOrdersV2Response, IOpenOrdersV2Response } from './types';

export const httpOrders = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/transactions/v1/orders/`
});

interface IFinishedOrdersRequest {
  page?: number;
  pageSize?: number;
  period?: string;
  type?: string[];
}

/**
 * Obtém a lista de ordens concluídas da carteira
 * @param portfolioId - ID da carteira
 * @param params - Parâmetros de paginação e filtro
 * @returns Promise com a resposta da lista de ordens concluídas
 */
export async function getFinishedOrdersV2List(
  portfolioId: string,
  params: IFinishedOrdersRequest = { page: 1 }
): Promise<IFinishedOrdersV2Response> {
  try {
    const typeArray = params.type || [];

    const baseParams = {
      page: params.page || 1,
      pageSize: params.pageSize || 5
    };

    let queryString = Object.entries(baseParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&');

    if (params.period) {
      queryString += `&period=${encodeURIComponent(params.period)}`;
    }

    typeArray.forEach(typeValue => {
      if (typeValue) {
        queryString += `&type[]=${encodeURIComponent(typeValue)}`;
      }
    });

    const url = `portfolio/${portfolioId}?${queryString}`;
    const response = await httpOrders.get(url);

    if (response) {
      return response as IFinishedOrdersV2Response;
    }

    throw new Error('Resposta vazia da API para ordens concluídas');
  } catch (error) {
    console.error(
      '[wealth-orders-v2] Error on getFinishedOrdersV2List:',
      error
    );
    throw error;
  }
}

/**
 * Obtém a lista de ordens abertas da carteira
 * @param portfolioId - ID da carteira
 * @returns Promise com a resposta da lista de ordens abertas
 */
export async function getOpenOrdersV2List(
  portfolioId: string
): Promise<IOpenOrdersV2Response> {
  try {
    const response = await httpOrders.get(`portfolio/${portfolioId}/openned`);

    if (response) {
      return response as IOpenOrdersV2Response;
    }

    throw new Error('Resposta vazia da API para ordens abertas');
  } catch (error) {
    console.error('[wealth-orders-v2] Error on getOpenOrdersV2List:', error);
    throw error;
  }
}
