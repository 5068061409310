
import { Component, Prop } from 'vue-property-decorator';
import {
  NavBar,
  Heading,
  HeadingDisplay,
  ContentDisplay,
  ButtonContainer,
  ButtonPrimary,
  HelperViewStatus,
  ListDisplay,
  SpinnerLoader
} from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import {
  BffWithdraw,
  IWealthToWithdrawResponseSuggestedSummary,
  ISendPortfolioToWithdrawSuggested
} from '@/modules/wealth/services/withdraw';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RouteMixin } from '@/modules/common/mixins/route';
import { mixins } from 'vue-class-component';
import { isImpersonate } from '@/modules/common/services/partner';
import { getUserB2B } from '@/modules/common/services/local-storage';
import { useLastReedemStore } from './../../store/redeemWithdrawPortfolio';
import { useBankList } from '../../composables/useBankList';

@Component({
  components: {
    NavBar,
    Heading,
    HeadingDisplay,
    ContentDisplay,
    ButtonContainer,
    ButtonPrimary,
    HelperViewStatus,
    ListDisplay,
    BackButton,
    SpinnerLoader
  }
})
export default class WealthPortfolioWithdrawReview extends mixins(RouteMixin) {
  public useBankListRef = useBankList();

  @Prop({ type: String, required: true })
  private readonly portfolioId!: string;

  readonly lastReedemStore = useLastReedemStore();

  private readonly bff = new BffWithdraw();
  readonly NebraskaColors = NebraskaColors;

  public wealthPortfolioWithdrawReviewData!: IWealthToWithdrawResponseSuggestedSummary;

  public isLoading = true;
  public isLoadingButton = false;
  public hasError = false;

  public bankId = this.useBankListRef.bankId.value
    ? this.useBankListRef.bankId.value.toString()
    : undefined;

  created() {
    this.getPorfolioToWithdrawSummary();
  }

  private async getPorfolioToWithdrawSummary() {
    try {
      const response = await this.bff.getPortfolioToWithdrawSuggestedSummary(
        this.portfolioId,
        this.lastReedemStore.amount!,
        this.bankId
      );
      this.wealthPortfolioWithdrawReviewData = response;
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  public executePayload() {
    let payload: ISendPortfolioToWithdrawSuggested = {
      portfolioId: this.portfolioId,
      amount: Number(this.lastReedemStore.amount),
      bankId: this.bankId
    };
    if (isImpersonate()) {
      payload = {
        ...payload,
        partnerId: this.partnerId()
      };
    }
    return payload;
  }

  public partnerId() {
    const partner = getUserB2B();
    return partner?._id || '';
  }

  public handleHelperClick() {
    this.lastReedemStore.clearRedeemInformation();
    return this.$router.push({
      name: 'wealth'
    });
  }

  public async handleNextClick() {
    try {
      this.isLoadingButton = true;
      this.lastReedemStore.saveLastRedeemInformation({
        amount: this.lastReedemStore.amount,
        wealthSuggestedSummary: this.wealthPortfolioWithdrawReviewData
      });
      await this.bff.sendPortfolioToWithdrawSuggested(this.executePayload());

      this.isLoadingButton = false;

      return this.$router.push({
        name: 'wealthPortfolioWithdrawCompleted'
      });
    } catch (error) {
      this.hasError = true;
    }
  }
}
