import { render, staticRenderFns } from "./SideSheet.vue?vue&type=template&id=d8588312&scoped=true&"
import script from "./SideSheet.vue?vue&type=script&lang=ts&"
export * from "./SideSheet.vue?vue&type=script&lang=ts&"
import style0 from "./SideSheet.vue?vue&type=style&index=0&id=d8588312&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8588312",
  null
  
)

export default component.exports