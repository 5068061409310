export interface IFixedIncomeReponse {
  title: string;
  filters: IFixedIncomeFilter[];
  items: IFixedIncomeProduct[];
  pagination: IPagination;
  marketStatus: IMarketStatus;
}

export interface IFixedIncomeFilter {
  label: string;
  value: string;
  isMultiple: boolean;
  selectedFilters: string | string[];
  options: { label: string; value: string }[];
}

export interface IFixedIncomeProduct {
  productId: string;
  risk: { label: string; value: string };
  name: string;
  category: string;
  minimumInvestment: string;
  maturityDate: string;
  liquidity: string;
  interestRate: string;
}

export interface IPagination {
  currentPage: number;
  previousPage: number;
  nextPage: number | null;
  pageSize: number;
  totalRecords: number;
}

export interface IProductsParams {
  pageIndex: number;
  pageSize: number;
  sortField?: string;
  sortDirection?: string;
  searchTerm?: string;
}

export interface ICustomOperationProduct {
  category: string;
  name: string;
  productId: string;
  minimumInvestment: string;
  maxGain: string;
  maxRisk: string;
  maturityDate: string;
  risk: {
    label: string;
    value: string;
  };
}

export interface ICustomOperationsResponse {
  title: string;
  items: ICustomOperationProduct[];
  pagination: IPagination;
  marketStatus: IMarketStatus;
}

export interface IGovernmentBondReponse {
  title: string;
  filters: IGovernmentBondFilter;
  items: IGovernmentBondProduct[];
  pagination: IPagination;
  marketStatus: IMarketStatus;
}

export interface IGovernmentBondProduct {
  productId: string;
  risk: { label: string; value: string };
  name: string;
  minimumInvestment: string;
  unityPrice: string;
  maturityDate: string;
  interestRate: string;
}

export interface IGovernmentBondFilter {
  selectedFilter: string;
  options: { label: string; value: string }[];
}

export interface IGovernmentBondParams {
  pageIndex: number;
  pageSize: number;
  segmentIds: string;
  sortField?: string;
  sortDirection?: string;
}

export interface IFundsReponse {
  title: string;
  filters: IFundsFilter[];
  items: IFundsProduct[];
  pagination: IPagination;
  marketStatus: IMarketStatus;
}

export interface IFundsProduct {
  productId: string;
  risk: { label: string; value: string };
  name: string;
  category: string;
  minimumInvestment: string;
  quotizationAtWithdraw: string;
  quotizationAtWithdrawType: string;
  liquidationAtWithdraw: string;
  liquidationAtWithdrawType: string;
  administrationTax: string;
  return: string;
  isProfessional: boolean;
  isQualified: boolean;
}

export interface IFundsFilter {
  label: string;
  value: string;
  isMultiple: boolean;
  selectedFilters: string | string[];
  options: { label: string; value: string }[];
}

export interface IProductDetails {
  isQualified: boolean;
  isProfessional: boolean;
  apiId: string;
  type: string;
  name: string;
  subCategory: string;
  details: { value: string; label: string }[];
  risk: { value: string; formatted: string };
  description: string;
  documents: IDocuments[];
}

export interface IDocuments {
  instrumentDetailId: number;
  description: string;
  title: string;
  url: string;
}

export interface IMarketStatus {
  isMarketOpen: boolean;
  icon: string;
  label: string;
  value: string;
}

export interface IProductHero {
  bar: string;
  head: string;
  title: string;
  symbol: string;
  items: IProductHeroItem[];
  bottom: IProductHeroBottom;
  buttons: IProductHeroButton[];
}

export interface IProductHeroItem {
  label: string;
  value: string;
}

export interface IProductHeroBottom {
  label: string;
  value: string;
}

export interface IProductHeroButton {
  label: string;
  isActive: boolean;
  action: string;
}

export enum IProductHeroButtonAction {
  INVEST = 'INVEST',
  CHOICE = 'CHOICE',
  WARNING = 'WARNING'
}

export interface IProductDetailsV2 {
  sections: IProductDetailsSection[];
  footer: IProductDetailFooter[];
}

export interface IProductDetailsSection {
  title: string;
  items: IProductsDetailItem[];
  message?: string;
}

export interface IProductsDetailItem {
  label: string;
  value: string;
  link?: string;
}

export interface IProductDetailFooter {
  icon: string;
  label: string;
}
