var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ContextColorProvider',{attrs:{"context":"trade"}},[_c('div',{class:_vm.computedNavBarClass},[_c('NavBar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('BackButton')]},proxy:true},{key:"center",fn:function(){return [_c('p',[_vm._v("Meus ativos")])]},proxy:true},{key:"right",fn:function(){return [(
            _vm.enableSecuritiesLending &&
            _vm.currentTabGroup === _vm.PositionsGroups.STOCK_LENDING
          )?_c('ButtonIconContextual',{staticClass:"positions__button-faq",attrs:{"icon":"EF0051","label":"Dúvidas frequentes"},on:{"click":_vm.handleFrequentlyAskedQuestions}}):_vm._e(),_c('ShowMoneyToggleButton')]},proxy:true}])}),(!_vm.error)?[(_vm.loading)?_c('TabsListLoader',{staticClass:"positions__tabs"}):(_vm.hasTabs)?_c('Tabs',{staticClass:"positions__tabs",attrs:{"on-change":_vm.onChangeTab,"selected-index":_vm.currentTabIndex}},[_c('TabList',{attrs:{"data-testid":"tab-list"}},_vm._l((_vm.tabsData),function(tab){return _c('Tab',{key:tab.description.value},[_vm._v(_vm._s(tab.description.formatted))])}),1)],1):_vm._e()]:_vm._e()],2),_c('main',{staticClass:"positions__main"},[(_vm.loading)?_c('TabContentLoader'):(_vm.error)?_c('HelperViewStatus',{staticClass:"positions__helper-view",attrs:{"title":"Não foi possível carregar os seus ativos","description":"Aguarde um momento e tente novamente","button-label":"Tentar novamente","status":"alert"},on:{"click":_vm.fetchPositionsTabs}}):(!_vm.hasTabs)?_c('HelperViewStatus',{staticClass:"positions__helper-view",attrs:{"title":"Nenhum ativo foi encontrado","description":"Você não tem posição em nenhum ativo","status":"information"}}):[(_vm.currentTabGroup === _vm.PositionsGroups.CASH_MARKET)?_c('CashMarket'):_vm._e(),(_vm.currentTabGroup === _vm.PositionsGroups.DERIVATIVES)?_c('Derivatives'):_vm._e(),(_vm.currentTabGroup === _vm.PositionsGroups.STOCK_LENDING)?_c('StockLending',{attrs:{"has-positions":_vm.hasStockLendingPositions,"paid-custody-active":_vm.paidCustodyActive},on:{"update:hasPositions":function($event){_vm.hasStockLendingPositions=$event},"update:has-positions":function($event){_vm.hasStockLendingPositions=$event},"update:paidCustodyActive":function($event){_vm.paidCustodyActive=$event},"update:paid-custody-active":function($event){_vm.paidCustodyActive=$event}}}):_vm._e(),(_vm.currentTabGroup === _vm.PositionsGroups.CUSTOM_OPERATIONS)?_c('CustomOperations'):_vm._e()]],2),(
      _vm.hasStockLendingPositions &&
      !_vm.paidCustodyActive &&
      _vm.enableSecuritiesLending
    )?_c('ModalPaidCustodyCanceled',{attrs:{"show-modal":_vm.modalPaidCustodyCanceled},on:{"close":_vm.handleCloseModalPaidCustodyCanceled}}):_vm._e(),(_vm.showButtonCancelPaidCustody && _vm.enableSecuritiesLending)?_c('footer',{staticClass:"positions__footer"},[_c('ButtonContainer',{scopedSlots:_vm._u([{key:"button-primary",fn:function(){return [_c('ButtonSecondary',{attrs:{"label":"Desativar custódia remunerada"},on:{"click":_vm.handleNextClick}})]},proxy:true}],null,false,2407440136)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }