import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=9f2e75b6&scoped=true&"
import script from "./OrderDetails.vue?vue&type=script&setup=true&lang=ts&"
export * from "./OrderDetails.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=9f2e75b6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f2e75b6",
  null
  
)

export default component.exports