import { number, object } from 'yup';
import { YupValidation } from '@/modules/common/helpers/yup-validation';

const schema = object().shape({
  totalInvested: number().required('Informe o valor em aplicações financeiras'),
  realEstate: number(),
  privatePension: number(),
  movableProperty: number()
});

export default new YupValidation(schema);
