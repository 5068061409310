import { render, staticRenderFns } from "./ProductSideSheetDetails.vue?vue&type=template&id=5e4d749b&scoped=true&"
import script from "./ProductSideSheetDetails.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ProductSideSheetDetails.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ProductSideSheetDetails.vue?vue&type=style&index=0&id=5e4d749b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4d749b",
  null
  
)

export default component.exports