
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { SingleInput, ShortcutValue } from '@warrenbrasil/nebraska-web';
import { VMoney } from 'v-money';
import { currencyToNumber } from '@/modules/common/helpers/currency';
import { EStatusType } from '@/modules/transaction/views/NewTransfer/helpers';
import {
  IValueFormatted,
  IWithdrawSuggestion
} from '@/modules/transaction/services/new-transfer';

@Component({
  components: {
    SingleInput,
    ShortcutValue
  }
})
export default class SingleInputMaskMoney extends Vue {
  @Ref('singleInput')
  readonly singleInput!: Vue;

  @Prop({ type: String, required: true })
  readonly amountValue!: string;

  @Prop({ type: Object, required: true })
  readonly balanceAvailable!: IValueFormatted;

  @Prop({ type: Object, required: true })
  readonly minimumWithdraw!: IValueFormatted;

  @Prop({ type: Array, required: true })
  readonly withdrawSuggestions!: IWithdrawSuggestion[];

  @Emit('change-amount-value')
  emitChangeAmountValue(_amountValue: string) {
    // Atualiza o valor da transferência com o valor emitido
  }

  @Emit('has-error')
  emitHasError(_error: boolean) {
    // Emite se erro no input
  }

  @Watch('amountValue', { immediate: true })
  watchAmountValue(amountValue: string) {
    const amountValueNumber = currencyToNumber(amountValue);

    if (this.singleInput?.$el) {
      const textarea = this.singleInput.$el.querySelector(
        'textarea'
      ) as HTMLElement | null;

      if (textarea) {
        if (amountValueNumber === 0) {
          this.setAmountValueHelperText('');
          this.emitHasError(true);
          textarea.classList.add('input-placeholder');
        } else if (
          amountValueNumber < 0 ||
          amountValueNumber < this.minimumWithdraw.value
        ) {
          this.setAmountValueHelperText(
            `O valor mínimo para resgate é de ${this.minimumWithdraw.formatted}. Escolha um valor maior ou igual para resgatar.`
          );
          this.emitHasError(true);
          textarea.classList.remove('input-placeholder');
        } else if (amountValueNumber > this.balanceAvailable.value) {
          this.setAmountValueHelperText(
            `O valor disponível para resgate desta carteira é de ${this.balanceAvailable.formatted}. Escolha um valor menor ou igual para resgatar.`
          );
          this.emitHasError(true);
        } else {
          this.setAmountValueHelperText('');
          this.emitHasError(false);
        }
      }
    }
  }

  amountValueHelperText = '';

  get value() {
    return this.amountValue;
  }

  get singleInputStatus() {
    return this.amountValueHelperText ? EStatusType.ERROR : EStatusType.DEFAULT;
  }

  mounted() {
    const textarea = this.singleInput.$el.querySelector(
      'textarea'
    ) as HTMLElement;

    VMoney(textarea, {
      value: { decimal: ',', thousands: '.', prefix: '', precision: 2 }
    });
  }

  shortcutClick(option: IWithdrawSuggestion) {
    const options = { minimumFractionDigits: 2 };
    const numberFormat = new Intl.NumberFormat('pt-BR', options);

    const textarea = this.singleInput.$el.querySelector(
      'textarea'
    ) as HTMLElement;
    textarea.classList.remove('input-placeholder');

    if (option.type === 'SET') {
      this.emitChangeAmountValue(
        numberFormat.format(Math.floor(option.value * 100) / 100)
      );
    } else {
      const formatted =
        parseFloat(option.value.toFixed(2)) +
        currencyToNumber(this.amountValue);
      this.emitChangeAmountValue(numberFormat.format(formatted));
    }
  }

  setAmountValueHelperText(helperText: string) {
    this.amountValueHelperText = helperText;
  }

  handleChangeAmountValue(event: Event) {
    const target = event.target as HTMLInputElement;
    this.emitChangeAmountValue(target.value);
  }

  clearAmountValue() {
    this.emitChangeAmountValue('');
  }
}
