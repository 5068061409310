var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('NavbarMenu',{ref:"navbarTemplateRef",class:[
    'app-navbar',
    {
      'app-navbar__impersonate--enabled': _vm.isImpersonateFlag,
      'app-navbar--loaded': !_vm.isShowingWelcomeSession
    }
  ],attrs:{"data-testid":"navbar-template","menu":_vm.menu,"is-transparent":_vm.isTransparent},on:{"tab-select":_vm.tabSelect},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('IconCountBadge',{attrs:{"show":_vm.unreadNotificationsCount > 0,"count":_vm.unreadNotificationsCount,"text-color":_vm.NebraskaColors.textNegative}},[_c('ButtonIconContextual',{attrs:{"label":"Menu","data-testid":"menu-button","icon":"AA0040"},on:{"click":_vm.openLeftSideBar}})],1)]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"app-navbar__right"},[_c('ShowMoneyToggleButton'),_c('NotificationsButton')],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }