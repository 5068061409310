export enum EAction {
  SUITABILITY = 'open-suitability',
  EMAIL = 'open-verify-email'
}

export interface IRegisterNotification {
  title: string;
  description: string;
  icon: string;
  action: EAction;
}

export interface IRegisterNotificationsData {
  registerNotifications: IRegisterNotification[];
}
