import { EPaidCustodySteps } from './types';
import { PaidCustodyActive } from '@/modules/trade/views/PaidCustody/components/PaidCustodyActive';
import { PaidCustodyCompleted } from '@/modules/trade/views/PaidCustody/components/PaidCustodyCompleted';
import PaidCustodyCancel from '@/modules/trade/views/PaidCustody/components/PaidCustodyCancel/PaidCustodyCancel.vue';

export const ObjectiveBuilderComponents = {
  [EPaidCustodySteps.ACTIVE]: PaidCustodyActive,
  [EPaidCustodySteps.COMPLETED]: PaidCustodyCompleted,
  [EPaidCustodySteps.CANCEL]: PaidCustodyCancel
};
