import { GtmTracker } from '@/modules/common/services/analytics';

const trackTradeEvent = GtmTracker.trackerWithDefaultProps({
  category: 'Trade'
});

const trackHomeTradeAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'home_broker',
  digital_experience: 'investor',
  experience_section: 'broker'
});

const trackOnboardingAmplitudeEvent = GtmTracker.trackerWithDefaultProps({
  page_screen: 'home_broker',
  digital_experience: 'investor',
  experience_section: 'onboarding'
});

export function trackTradeViewLoad() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    event_name: 'view'
  });
}

export function trackChartFilterClick(filter: string) {
  trackTradeEvent({
    action: filter,
    label: 'Filtro de datas'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'select_filter',
    event_target: filter
  });
}

export function trackSearchButtonClick() {
  trackTradeEvent({
    action: 'Clique',
    label: 'Buscar Ativo'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'search_asset'
  });
}

export function trackTradeDepositClick() {
  trackTradeEvent({
    action: 'Depositar',
    label: 'Home Dashboard'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'deposit'
  });
}

export function trackTradeWithdrawClick() {
  trackTradeEvent({
    action: 'Resgatar',
    label: 'Home Dashboard'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: 'withdraw'
  });
}

export function trackTradeTransactionClick() {
  trackTradeEvent({
    action: 'Fazer transação',
    label: 'Home Dashboard'
  });
}

export function trackRequestHomeBrokerClick() {
  trackTradeEvent({
    label: 'Solicitar Home Broker',
    action: 'Clique em Solicitar'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'require_homebroker',
    event_name: 'require'
  });
}

export function trackCancelHomeBrokerClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'require_homebroker',
    event_name: 'cancel'
  });
}

export function trackOpenBlockedBalanceModal() {
  trackTradeEvent({
    action: 'Clique Ler Mais',
    label: 'Modal Saldo Bloqueado',
    eventType: 'click',
    pageScreenSection: '',
    eventName: 'wrn_click'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'blocked',
    event_name: 'read_more',
    event_target: 'Ler mais sobre margem de garantia'
  });
}

export function trackHomeBrokerClick(buttonLabel: string) {
  trackTradeEvent({ label: 'Home Trade', action: `Clique em ${buttonLabel}` });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'acess_homebroker',
    event_target: buttonLabel
  });
}

export function trackHelpClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'view_faq'
  });
}

export function trackStatementAndNotesClick() {
  trackTradeEvent({ action: 'Click', label: 'Extrato e Notas' });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'body',
    event_name: 'view_extracts_and_trading_notes'
  });
}

export function trackSubscriptionActiveHome(
  instrumentType: string,
  instrumentName: string,
  instrumentSymbol: string
) {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'asset_subscription',
    event_name: 'select_asset',
    instrument_type: instrumentType,
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackViewAllPositions() {
  trackTradeEvent({ action: 'Clique Ver meus ativos', label: 'Meus Ativos' });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'my_assets',
    event_name: 'view_my_assets'
  });
}

export function trackViewAllWatchlist() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'favorites',
    event_name: 'view_my_favorites'
  });
}

export function trackInstrumentPositionClick(
  instrumentSymbol: string,
  instrumentName = ''
) {
  trackTradeEvent({
    action: `Clique em ${instrumentSymbol}`,
    label: 'Meus Ativos'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'my_assets',
    event_name: 'select_asset',
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackPositionOptionClick(optionLabel: string) {
  trackTradeEvent({
    action: `Clique ordernar por ${optionLabel}`,
    label: 'Meus ativos'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'my_assets',
    event_name: 'select_sort',
    event_target: optionLabel
  });
}

export function trackAvailableBalanceLiquidateDetails(date: string) {
  trackTradeEvent({
    action: `Clique ${date}`,
    label: 'Modal a liquidar'
  });
}

export function trackLiquidateDateClick(label: string) {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'to_liquidate',
    event_name: 'select_date',
    event_target: label
  });
}

export function trackAvailableBalanceLiquidate() {
  trackTradeEvent({
    action: 'Clique liquidar',
    label: 'Modal disponível para investir'
  });
}

export function trackWithdrawActionClick() {
  trackTradeEvent({
    label: 'Modal disponível para resgate',
    action: 'Clique resgatar'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'available_to_withdraw',
    event_name: 'withdraw'
  });
}

export function trackWithdrawLiquidateClick() {
  trackTradeEvent({
    label: 'Modal disponível para resgate',
    action: 'Clique a liquidar'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'available_to_withdraw',
    event_name: 'view_to_liquidate'
  });
}

export function trackInstrumentWatchlistClick(
  instrumentSymbol: string,
  instrumentName = ''
) {
  trackTradeEvent({
    action: instrumentSymbol,
    label: 'Favoritos'
  });
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'favorites',
    event_name: 'select_asset',
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackDashStatusButtonClick(dashStatusAction?: string | null) {
  if (!dashStatusAction) return;

  type TrackEventType = {
    start_register: string;
    verify_email: string;
    start_suitability: string;
  };

  const dashActionsEvents: TrackEventType = {
    start_register: 'insert_data_register',
    verify_email: 'resend_email',
    start_suitability: 'discover_profile'
  };

  const eventAction = dashStatusAction as keyof TrackEventType;

  trackOnboardingAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard',
    event_name: dashActionsEvents[eventAction]
  });
}

export function trackMarketTodayClick(
  instrumentSymbol: string,
  instrumentName = '',
  instrumentType: string
) {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'market_list',
    event_name: 'select_asset',
    instrument_type: instrumentType,
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackMarketTodayFilter(target: string) {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'market_list',
    event_name: 'select_filter',
    event_target: target
  });
}

export function trackCustomOperationsSelectChange(optionLabel: string) {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations',
    event_name: 'select_sort',
    event_target: optionLabel
  });
}

export function trackCustomOperationRowClick(
  instrumentSymbol: string,
  instrumentName = '',
  instrumentType = ''
) {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations',
    event_name: 'select_custom_operation',
    instrument_type: instrumentType,
    instrument_name: instrumentName,
    instrument_symbol: instrumentSymbol
  });
}

export function trackBalancesRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'dashboard',
    event_name: 'request_balance_resume_error'
  });
}

export function trackAccountStatusRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'summary',
    event_name: 'request_account_status_error'
  });
}

export function trackMarketStatusRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'summary',
    event_name: 'try_again'
  });
}

export function trackDashStatusRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'dashboard_chart',
    event_name: 'request_dashboard_status_error'
  });
}

export function trackChartRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'dashboard_chart',
    event_name: 'request_chart_error'
  });
}

export function trackChartRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'dashboard_chart',
    event_name: 'try_again'
  });
}

export function trackPositionsRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'my_assets',
    event_name: 'request_positions_error'
  });
}

export function trackPositionsRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'my_assets',
    event_name: 'try_again'
  });
}

export function trackWatchlistRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'favorites',
    event_name: 'request_watchlist_error'
  });
}

export function trackWatchlistRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'favorites',
    event_name: 'try_again'
  });
}

export function trackOrdersRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'orders',
    event_name: 'request_orders_error'
  });
}

export function trackOrdersRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'orders',
    event_name: 'try_again'
  });
}

export function trackSubscriptionsRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'asset_subscription',
    event_name: 'request_subscription_error'
  });
}

export function trackSubscriptionsRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'asset_subscription',
    event_name: 'try_again'
  });
}

export function trackCustomOperationsRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'custom_operations',
    event_name: 'request_custom_operations_error'
  });
}

export function trackCustomOperationsRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'custom_operations',
    event_name: 'try_again'
  });
}

export function trackProvisionedEventsRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'upcoming_events',
    event_name: 'request_provisioned_events_error'
  });
}

export function trackProvisionedEventsRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'upcoming_events',
    event_name: 'try_again'
  });
}

export function trackViewAllEventsProvisionedClick() {
  trackHomeTradeAmplitudeEvent({
    page_screen: 'upcoming_events',
    event_type: 'click',
    event_name: 'view_all'
  });
}

export function trackMarketHighlightsRequestError() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'load',
    page_screen_section: 'market_list',
    event_name: 'request_market_highlights_error'
  });
}

export function trackMarketHighlightsRetryClick() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'market_list',
    event_name: 'try_again'
  });
}

export function trackOrdersRowClick(label: string) {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'orders',
    event_name: 'select_order',
    event_target: label
  });
}

export function trackOrdersViewMore() {
  trackHomeTradeAmplitudeEvent({
    event_type: 'click',
    page_screen_section: 'orders',
    event_name: 'view_all'
  });
}
