import { isOrchestratorActive } from '@/modules/common/store/RegulatoryUpdateRoutesOrchestrator';
import { NavigationGuard } from 'vue-router';

export const regulatoryUpdateGuard: NavigationGuard = (_to, _from, next) => {
  const isRegulatoryUpdateActive = isOrchestratorActive();

  if (!isRegulatoryUpdateActive) {
    return next({
      name: 'regulatory-update'
    });
  }

  next();
};
