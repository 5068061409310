
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Heading,
  IconButton,
  RoundedIcon,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { StatusInformationCard } from './components/StatusInformationCard';
import { IResumeCardItem, ResumeCard } from '../../components/ResumeCard';
import { Footer } from '../../components/FooterBtnContainer';
import { SuccessCurtain } from './components/SuccessCurtain';
import { namespace } from 'vuex-class';
import { ILastRedeemInfoPayload } from '../../store/redeem/redeem-types';
import { trackViewLoad, trackBackToPortfoliosView } from './track-events';
import { isImpersonate } from '@/modules/common/services/partner';
import {
  StorageKeys,
  getUserB2B
} from '@/modules/common/services/local-storage';
import { B2bPartnerType } from '@/types/models/Customer/AdvisorProfile';

const redeemModule = namespace('RedeemModule');

@Component({
  components: {
    Heading,
    ResumeCard,
    Footer,
    IconButton,
    RoundedIcon,
    StatusInformationCard,
    SuccessCurtain,
    BaseText
  }
})
export default class WealthProductWithdrawCompleted extends Vue {
  @redeemModule.Mutation('clearRedeemInformation')
  private readonly clearRedeemInformation!: () => void;

  @redeemModule.Getter('lastRedeemData')
  public readonly lastRedeemPortfolioInfo!: ILastRedeemInfoPayload;

  @Prop({ type: String, required: true })
  private readonly portfolioId!: string;

  public readonly NebraskaColors = NebraskaColors;
  public showCurtain = true;

  beforeMount() {
    if (!this.hasRedeemStored) {
      this.goBackToWallet();
    } else {
      trackViewLoad();
    }
  }

  mounted() {
    setTimeout(() => {
      this.showCurtain = false;
    }, 3000);
  }

  public goBackToWallet() {
    this.clearRedeemInformation();
    trackBackToPortfoliosView();
    this.$router.replace({
      name: 'wealthPortfolio',
      params: { id: this.portfolioId }
    });
  }

  get isPartnerManager(): boolean {
    const partner = getUserB2B();
    return partner?.advisorProfile?.type === B2bPartnerType.MANAGER;
  }

  get hasRedeemStored() {
    return (
      this.lastRedeemPortfolioInfo && this.lastRedeemPortfolioInfo.portfolio
    );
  }

  get summaryFormatted(): IResumeCardItem[] {
    const { summaryPreview } = this.lastRedeemPortfolioInfo;
    return summaryPreview
      ? [
          {
            title: 'Resumo',
            nestedItems: [
              { label: 'Destino', value: 'Conta Digital' },
              {
                label: 'Total bruto solicitado',
                value: summaryPreview.grossValue.formatted
              }
            ],
            total: {
              label: 'Total líquido estimado',
              value: summaryPreview.netValue.formatted
            }
          }
        ]
      : [];
  }

  get productsFormatted(): IResumeCardItem[] {
    const { products } = this.lastRedeemPortfolioInfo;
    return products
      ? products.map(product => ({
          title: product.name,
          nestedItems: [
            {
              label: 'Liquidação',
              value: product.liquidationDate
            },
            {
              label: 'Valor líquido estimado',
              value: product.netValue.formatted
            }
          ]
        }))
      : [];
  }

  get showApprovalText(): boolean {
    return (
      isImpersonate() &&
      localStorage.getItem(StorageKeys.IsBrandOwner) === 'false' &&
      !this.isPartnerManager
    );
  }
}
