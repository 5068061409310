import {
  IFilter,
  IFinishedOrdersV2Response,
  IOrderGroup,
  IOrderItem,
  IPeriodFilterOption,
  IOpenOrdersV2Response,
  IOrderValue,
  IOrderDescription,
  IOrderHeaderDetails,
  IAllocationDetails,
  IOrderProductGroup,
  IProductDetails
} from '@/modules/wealth/services/wealth-orders-v2/types';

const ordersPerPage = 5;

const randomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const randomFromArray = <T>(arr: T[]): T =>
  arr[Math.floor(Math.random() * arr.length)];

type OrderType = 'Investimento' | 'Resgate' | 'Rebalanceamento';
type OrderTypeInfo = { title: OrderType; icon: string };

const orderTypes: OrderTypeInfo[] = [
  { title: 'Investimento', icon: 'EA0130' },
  { title: 'Resgate', icon: 'EA0140' },
  { title: 'Rebalanceamento', icon: 'EA0150' }
];

const productGroups = [
  {
    title: 'Fundos Renda Fixa',
    products: [
      'Warren Cash FI RF',
      'Warren Fixed Income FIC FIRF',
      'Warren High Yield FIC FIRF CP'
    ]
  },
  {
    title: 'Fundos de Ações',
    products: ['Warren Tech FIA', 'Warren Small Caps FIA', 'Warren Brasil FIA']
  },
  {
    title: 'Fundos Multimercado',
    products: [
      'Warren Dynamic FIC FIM',
      'Warren Global ESG FIC FIM',
      'Warren Infaltion FIC FIM'
    ]
  }
];

const statusColors = {
  positive: '#356D3E',
  negative: '#92363E',
  info: '#34639C'
};

type OrderStatus = 'Processing' | 'Finished' | 'Canceled';

const generateOrderValue = (): IOrderValue => {
  const value = randomNumber(1000, 100000);
  return {
    value,
    formatted: `R$ ${value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2
    })}`
  };
};

const generateDescription = (
  isOpen = false,
  status?: OrderStatus
): IOrderDescription | null => {
  if (isOpen) {
    const descriptions = [
      'Em processamento',
      'D+1 útil',
      'D+30 úteis',
      'Em análise',
      'Agendado para 25 mar'
    ];
    return {
      label: randomFromArray(descriptions),
      color: statusColors.info
    };
  }

  if (status === 'Canceled') {
    return {
      label: 'Cancelado',
      color: statusColors.negative,
      colorStatus: 'negative'
    };
  }

  return null;
};

const generateAllocationDetails = (): IAllocationDetails => {
  const value = randomNumber(0, 100);
  return {
    value,
    formatted: `${value}% do valor foi alocado`
  };
};

const generateHeaderDetails = (
  orderType: OrderTypeInfo,
  value: string,
  status: OrderStatus
): IOrderHeaderDetails => {
  const date = `${randomNumber(1, 28)} mar`;

  const descriptions: Record<OrderType, Record<OrderStatus, string>> = {
    Investimento: {
      Finished: 'Investimento realizado com sucesso.',
      Processing: 'Seu investimento está sendo processado.',
      Canceled: 'O investimento não pôde ser concluído.'
    },
    Resgate: {
      Finished: 'Resgate realizado e creditado em sua Conta Digital.',
      Processing: 'Seu resgate está em processamento.',
      Canceled: 'O resgate não pôde ser concluído.'
    },
    Rebalanceamento: {
      Finished: 'Rebalanceamento realizado com sucesso.',
      Processing: 'Seu rebalanceamento está em processamento.',
      Canceled: 'O rebalanceamento não pôde ser concluído.'
    }
  };

  const description = descriptions[orderType.title as OrderType][status];

  const allocation = generateAllocationDetails();
  const finishedAmount = status === 'Canceled' ? 'R$ 0,00' : value;

  return {
    title: orderType.title,
    icon: orderType.icon,
    value,
    statusColorAnimation: status,
    description,
    allocation:
      status === 'Canceled'
        ? { value: 0, formatted: '0% processado' }
        : allocation,
    finishedAmount,
    createdAt: `Solicitado em ${date}`,
    finishedAt:
      status === 'Processing'
        ? 'Em processamento'
        : status === 'Canceled'
        ? `Cancelado em ${date}`
        : `Concluído em ${date}`
  };
};

const generateProductDetails = (
  productName: string,
  status: OrderStatus,
  statusLabel: string,
  colorStatus: 'positive' | 'negative' | 'info',
  date: string,
  value: string
): IProductDetails => {
  const hasButton = Math.random() < 0.2;

  return {
    title: productName,
    description: {
      label: statusLabel,
      color: statusColors[colorStatus]
    },
    list: [
      { label: 'Data da operação', value: `${date}/2024` },
      { label: 'Valor', value },
      { label: 'Status', value: statusLabel }
    ],
    button: hasButton
      ? {
          label: 'Ver trânsito',
          action: 'open_transit'
        }
      : null
  };
};

const generateProducts = (
  orderType: OrderTypeInfo,
  status: OrderStatus
): IOrderProductGroup[] => {
  const groups: IOrderProductGroup[] = [];

  if (orderType.title === 'Rebalanceamento') {
    ['Investimentos', 'Resgates'].forEach(groupTitle => {
      const totalProducts = randomNumber(2, 4);
      const groupProducts = Array(totalProducts)
        .fill(null)
        .map(() => {
          const productName = randomFromArray(
            productGroups.flatMap(g => g.products)
          );
          const value = generateOrderValue().formatted;
          const date = `${randomNumber(1, 28)} mar`;

          let colorStatus: 'positive' | 'negative' | 'info';
          let statusLabel: string;
          let statusIcon: string;

          switch (status) {
            case 'Finished':
              colorStatus = 'positive';
              statusLabel = `Concluído em ${date}`;
              statusIcon = 'EF0070';
              break;
            case 'Processing':
              colorStatus = 'info';
              statusLabel = 'Em processamento';
              statusIcon = 'EF0090';
              break;
            case 'Canceled':
              colorStatus = 'negative';
              statusLabel = 'Cancelado';
              statusIcon = 'EF0060';
              break;
          }

          return {
            name: productName,
            orderId: `${Math.random().toString(36).substr(2, 9)}`,
            value,
            description: {
              label: statusLabel,
              icon: statusIcon,
              color: statusColors[colorStatus],
              colorStatus
            },
            details: generateProductDetails(
              productName,
              status,
              statusLabel,
              colorStatus,
              date,
              value
            )
          };
        });

      if (groupProducts.length > 0) {
        groups.push({
          title: groupTitle,
          items: groupProducts
        });
      }
    });
  } else {
    const totalProducts = randomNumber(1, 3);
    const groupProducts = Array(totalProducts)
      .fill(null)
      .map(() => {
        const productName = randomFromArray(
          productGroups.flatMap(g => g.products)
        );
        const value = generateOrderValue().formatted;
        const date = `${randomNumber(1, 28)} mar`;

        let colorStatus: 'positive' | 'negative' | 'info';
        let statusLabel: string;
        let statusIcon: string;

        switch (status) {
          case 'Finished':
            colorStatus = 'positive';
            statusLabel = `Concluído em ${date}`;
            statusIcon = 'EF0070';
            break;
          case 'Processing':
            colorStatus = 'info';
            statusLabel = 'Em processamento';
            statusIcon = 'EF0090';
            break;
          case 'Canceled':
            colorStatus = 'negative';
            statusLabel = 'Cancelado';
            statusIcon = 'EF0060';
            break;
        }

        return {
          name: productName,
          orderId: `${Math.random().toString(36).substr(2, 9)}`,
          value,
          description: {
            label: statusLabel,
            icon: statusIcon,
            color: statusColors[colorStatus],
            colorStatus
          },
          details: generateProductDetails(
            productName,
            status,
            statusLabel,
            colorStatus,
            date,
            value
          )
        };
      });

    if (groupProducts.length > 0) {
      groups.push({
        title: null,
        items: groupProducts
      });
    }
  }

  return groups;
};

const generateOrderItem = (isOpen = false): IOrderItem => {
  const orderType = randomFromArray<OrderTypeInfo>(orderTypes);
  const value = generateOrderValue();

  const status: OrderStatus = isOpen
    ? 'Processing'
    : randomFromArray<OrderStatus>(['Finished', 'Canceled']);

  return {
    value,
    title: orderType.title,
    icon: orderType.icon,
    description: generateDescription(isOpen, status),
    details: {
      header: generateHeaderDetails(orderType, value.formatted, status),
      products: generateProducts(orderType, status)
    }
  };
};

const generateFinishedOrderGroups = (count: number): IOrderGroup[] => {
  const months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio'];
  const groups: IOrderGroup[] = [];
  let remainingItems = count;

  while (remainingItems > 0) {
    const itemsInGroup = Math.min(randomNumber(1, 5), remainingItems);
    groups.push({
      date: `${randomNumber(1, 28)} de ${randomFromArray(months)}`,
      items: Array(itemsInGroup)
        .fill(null)
        .map(() => generateOrderItem(false))
    });
    remainingItems -= itemsInGroup;
  }

  return groups;
};

const mockPeriodFilter: IPeriodFilterOption[] = [
  { label: 'Todos', value: '', checked: false },
  { label: '7 dias', value: '7', checked: false },
  { label: '30 dias', value: '30', checked: false },
  { label: '90 dias', value: '90', checked: false }
];

const mockFilters: IFilter[] = [
  {
    multiple: false,
    type: {
      label: 'Tipo',
      value: 'type'
    },
    options: [
      { label: 'Todos', value: '', checked: false },
      { label: 'Investimento', value: 'Investment', checked: false },
      { label: 'Resgate', value: 'Redemption', checked: false },
      { label: 'Rebalanceamento', value: 'Rebalance', checked: false }
    ]
  }
];

export const mockOrdersV2Responses = {
  getFinishedOrdersResponse: (
    page = 1,
    filterParams?: { period?: string; type?: string }
  ): IFinishedOrdersV2Response => {
    const allGroups = generateFinishedOrderGroups(100);
    const hasFilters = filterParams?.period || filterParams?.type;
    const filteredGroups = hasFilters ? allGroups.slice(-3) : allGroups;

    if (filterParams?.type) {
      mockFilters[0].options = mockFilters[0].options.map(option => ({
        ...option,
        checked: option.value === filterParams.type
      }));
    } else {
      mockFilters[0].options = mockFilters[0].options.map(option => ({
        ...option,
        checked: option.value === ''
      }));
    }

    const updatedPeriodFilter = mockPeriodFilter.map(option => ({
      ...option,
      checked: filterParams?.period
        ? option.value === filterParams.period
        : option.value === ''
    }));

    const allItems: { item: IOrderItem; groupDate: string }[] = [];
    filteredGroups.forEach((group: IOrderGroup) => {
      group.items.forEach((item: IOrderItem) => {
        allItems.push({ item, groupDate: group.date });
      });
    });

    const totalItems = allItems.length;
    const totalPages = Math.max(1, Math.ceil(totalItems / ordersPerPage));
    const validPage = Math.min(page, totalPages);
    const startIndex = (validPage - 1) * ordersPerPage;
    const endIndex = Math.min(startIndex + ordersPerPage, totalItems);
    const itemsForCurrentPage = allItems.slice(startIndex, endIndex);

    const groupsMap = new Map<string, IOrderItem[]>();
    itemsForCurrentPage.forEach(itemInfo => {
      if (!groupsMap.has(itemInfo.groupDate)) {
        groupsMap.set(itemInfo.groupDate, []);
      }
      groupsMap.get(itemInfo.groupDate)?.push(itemInfo.item);
    });

    const ordersForCurrentPage: IOrderGroup[] = [];
    groupsMap.forEach((items, date) => {
      ordersForCurrentPage.push({ date, items });
    });

    return {
      page: validPage,
      pageSize: ordersPerPage,
      count: itemsForCurrentPage.length,
      totalCount: totalItems,
      totalPages,
      hasPreviousPage: validPage > 1,
      hasNextPage: validPage < totalPages,
      orders: ordersForCurrentPage,
      periodFilter: updatedPeriodFilter,
      filters: mockFilters
    };
  },

  getOpenOrdersResponse: (): IOpenOrdersV2Response => {
    const orders = Array(20)
      .fill(null)
      .map(() => generateOrderItem(true));

    return {
      count: orders.length,
      orders
    };
  }
};
