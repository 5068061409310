import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { RiskCell } from '@/modules/catalog/components';

const productLabel = (products: number) => {
  return products > 1 ? `${products} Produtos` : `${products} Produto`;
};

export const columns = (products: number): DataTableProps.Column[] => {
  return [
    {
      label: productLabel(products),
      sort: 'fantasyName',
      width: '25%',
      getter: ({ name, category }) => ({
        text: name,
        description: category
      })
    },
    {
      label: 'Mínimo inicial',
      align: DataTableProps.Align.Right,
      sort: 'minimumInitialInvestment',
      width: '15%',
      getter: 'minimumInvestment'
    },
    {
      label: 'Cotização do resgate',
      align: DataTableProps.Align.Right,
      sort: 'daysToQuotizationAtWithdraw',
      width: '15%',
      getter: ({ quotizationAtWithdraw, quotizationAtWithdrawType }) => ({
        text: quotizationAtWithdraw,
        description: quotizationAtWithdrawType
      })
    },
    {
      label: 'Taxa de adm. (a.a)',
      align: DataTableProps.Align.Right,
      sort: 'administrationTax',
      width: '15%',
      getter: 'administrationTax'
    },
    {
      label: 'Rentab. (12M)',
      align: DataTableProps.Align.Right,
      sort: 'performances.performanceLastTwelveMonths',
      width: '15%',
      getter: 'return'
    },
    {
      label: 'Risco',
      component: RiskCell,
      align: DataTableProps.Align.Right,
      sort: 'riskScore',
      width: '15%',
      getter: ({ risk }) => ({
        risk
      })
    }
  ];
};
