
import { Component, Prop } from 'vue-property-decorator';
import {
  NavBar,
  HeadingDisplay,
  BaseText,
  ContentDisplay,
  ButtonContainer,
  ButtonPrimary,
  HelperViewStatus,
  ListDisplay,
  SpinnerLoader,
  ButtonBoxIcon,
  InitialAvatarImage
} from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import ModalPreview from './components/ModalPreview/ModalPreview.vue';
import {
  BffWithdraw,
  IWealthToWithdrawResponseSuggested,
  IWealthToWithdrawResponseSuggestedPreview
} from '@/modules/wealth/services/withdraw';
import { getBankList } from '@/modules/onboarding/services';
import { SingleInputMaskMoney } from './components/SingleInputMaskMoney';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RouteMixin } from '@/modules/common/mixins/route';
import { mixins } from 'vue-class-component';
import { formatCurrency } from '@/modules/common/helpers/currency';
import { useLastReedemStore } from './../../store/redeemWithdrawPortfolio';
import { useBankList } from '@/modules/wealth/composables/useBankList';

@Component({
  components: {
    NavBar,
    HeadingDisplay,
    BaseText,
    ContentDisplay,
    ButtonContainer,
    ButtonPrimary,
    HelperViewStatus,
    ListDisplay,
    BackButton,
    SingleInputMaskMoney,
    SpinnerLoader,
    ModalPreview,
    ButtonBoxIcon,
    InitialAvatarImage
  }
})
export default class WealthPortfolioWithdraw extends mixins(RouteMixin) {
  @Prop({ type: String, required: true })
  private readonly portfolioId!: string;

  public useBankListRef = useBankList();

  readonly lastReedemStore = useLastReedemStore();

  private readonly bff = new BffWithdraw();
  readonly NebraskaColors = NebraskaColors;

  public wealth!: IWealthToWithdrawResponseSuggested;

  public wealthPortfolioWithdrawPreviewData!: IWealthToWithdrawResponseSuggestedPreview;

  public withdrawOptions = false;

  public valueToWithdraw = '';
  public disabledInput = true;

  public titleHelper = '';
  public descriptionHelper = '';
  public buttonHelper = '';

  public isLoading = true;
  public isLoadingButton = false;
  public hasError = false;

  public showModal = false;

  created() {
    this.getPorfolioToWithdraw();
    this.useBankListRef.toggleBankId('');
  }

  private redirectWealthProductWithdraw() {
    this.$router.push({
      name: 'wealthProductWithdrawal',
      params: {
        portfolioId: this.portfolioId
      }
    });
  }

  private async getPorfolioToWithdraw() {
    try {
      const response = await this.bff.getPortfolioToWithdrawSuggested(
        this.portfolioId
      );
      response.data.isCustom && this.redirectWealthProductWithdraw();
      this.wealth = response;
      if (this.wealth.isWithdrawPending) {
        this.setError(
          'Resgate em aberto',
          'Não é possível solicitar um resgate enquanto existe outro em processamento na mesma carteira. Acompanhe na lista de ordens.',
          'Ir para ordens'
        );
      }
    } catch (error) {
      this.setError(
        'Falha ao carregar',
        'Tivemos um problema de comunicação, tente novamente daqui alguns minutos.',
        'Ir para ordens'
      );
    } finally {
      this.isLoading = false;
    }
  }

  get minimumWithdrawNormalize() {
    return `Resgate mínimo: ${this.wealth.data.minimumWithdraw.formatted}`;
  }

  get currentBalance() {
    return `Saldo atual em carteira: ${this.wealth.data.currentBalance.formatted}`;
  }

  get balanceAfterWithdraw() {
    return `R$ ${formatCurrency(this.calculateBalanceAfterWithdraw())}`;
  }

  private calculateBalanceAfterWithdraw() {
    const valueToWithdrawNumber = Number(
      this.valueToWithdraw.replaceAll('.', '').replace(',', '.')
    );
    return this.wealth.data.currentBalance.value - valueToWithdrawNumber;
  }

  public handleInputKeys(amountValue: string) {
    this.valueToWithdraw = amountValue;
  }

  private setError(title: string, message: string, buttonLabel: string) {
    this.hasError = true;
    this.titleHelper = title;
    this.descriptionHelper = message;
    this.buttonHelper = buttonLabel;
    this.disabledInput = true;
  }

  public showInputButton(_error: boolean) {
    if (_error) {
      this.disabledInput = true;
    } else {
      this.disabledInput = false;
    }
  }

  private async getPortfolioToWithdrawSuggestedPreview() {
    try {
      const valueToWithdrawNumber = Number(
        this.valueToWithdraw.replaceAll('.', '').replace(',', '.')
      );

      this.lastReedemStore.saveLastRedeemInformation({
        amount: valueToWithdrawNumber.toString()
      });

      const response = await this.bff.getPortfolioToWithdrawSuggestedPreview(
        this.portfolioId,
        valueToWithdrawNumber.toString()
      );
      this.wealthPortfolioWithdrawPreviewData = response;
    } catch (error) {
      this.hasError = true;
    }
  }

  public handleHelperClick() {
    return this.$router.push({
      name: 'wealthOrders'
    });
  }

  public handleCloseModal() {
    this.lastReedemStore.clearRedeemInformation();
    this.showModal = false;
  }

  public async showModalPreview() {
    try {
      this.hasError = false;
      this.isLoadingButton = true;

      await Promise.all([
        this.getPortfolioToWithdrawSuggestedPreview(),
        this.getWithdrawOptions()
      ]);

      this.showModal = true;
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoadingButton = false;
    }
  }

  private async getWithdrawOptions() {
    const walletWithDirectRedemption = this.wealth.data.canRedeemDirectly;

    if (walletWithDirectRedemption) {
      try {
        const response = await getBankList();

        if (response.externalBankAccounts.bankAccounts.length > 0) {
          this.withdrawOptions = true;
          this.useBankListRef.toggleBankList(response);
        }
      } catch (error) {
        this.hasError = true;
      }
    }
  }

  public handleNextClick() {
    const valueToWithdrawNumber = Number(
      this.valueToWithdraw.replaceAll('.', '').replace(',', '.')
    );

    this.lastReedemStore.saveLastRedeemInformation({
      amount: valueToWithdrawNumber.toString()
    });

    const walletWithDirectRedemption = this.wealth.data.canRedeemDirectly;
    const existWithdrawOptions = this.withdrawOptions;

    if (walletWithDirectRedemption && existWithdrawOptions) {
      return this.$router.push({
        name: 'wealthPortfolioWithdrawDestination'
      });
    }

    return this.$router.push({
      name: 'wealthPortfolioWithdrawReview'
    });
  }
}
