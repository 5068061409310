
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  BaseText,
  Heading,
  HeadingDisplay,
  SkeletonLoader,
  ButtonSecondary,
  ButtonIconContextual
} from '@warrenbrasil/nebraska-web';
import { HeadingDisplayLoader } from '@/modules/trade/components/HeadingDisplayLoader';
import { Money } from '@/modules/common/components/MoneyV2';
import { BalanceDetailsModal } from '@/modules/transaction/views/Transactions/components/Balances/components/BalanceDetailsModal';
import { IHeroBalances } from '@/modules/trade/services/account';
import { RequestStatus } from '@/modules/trade/store/trade';
import { useDashStore } from '@/modules/common/store/DashModule';
import { data } from './data';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    BalanceDetailsModal,
    BaseText,
    Heading,
    HeadingDisplay,
    SkeletonLoader,
    ButtonSecondary,
    HeadingDisplayLoader,
    Money,
    ButtonIconContextual
  }
})
export default class PageTitle extends Vue {
  readonly data = data;
  private readonly dashModule = useDashStore();
  private isDetailsModalOpen = false;

  @tradePositionsModule.State('balancesBrokerRequestStatus')
  readonly balancesBrokerRequestStatus!: RequestStatus;

  @tradePositionsModule.State('balances')
  readonly balances?: IHeroBalances | null;

  @tradePositionsModule.Action('fetchBalances')
  readonly fetchBalances!: Function;

  @tradePositionsModule.State('balanceRequestStatus')
  readonly balanceRequestStatus!: RequestStatus;

  readonly RequestStatus = RequestStatus;

  handleRetryClick() {
    this.fetchBalances();
  }

  get showMoney() {
    return this.dashModule;
  }

  get title() {
    return this.balances?.totalBalance?.formatted || '-';
  }

  get subtitle() {
    return this.balances?.totalBalanceUpdatedAt?.formatted || '-';
  }

  get marginStock() {
    return this.balances?.marginStock || null;
  }
}
