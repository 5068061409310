import { rest } from 'msw';
import { mockOrdersV2Responses } from './data';
import { Scenarios } from '../../../types';

export const ordersV2Handlers = [
  rest.get(
    `${process.env.VUE_APP_BFF_URL}/transactions/v1/orders/portfolio/*/openned`,
    async (_req, res, ctx) => {
      const response = mockOrdersV2Responses.getOpenOrdersResponse();

      await new Promise(resolve => setTimeout(resolve, 1000));

      return res(ctx.status(200), ctx.json(response));
    }
  ),

  rest.get(
    `${process.env.VUE_APP_BFF_URL}/transactions/v1/orders/portfolio/*`,
    async (req, res, ctx) => {
      const page = Number(req.url.searchParams.get('page')) || 1;

      const type = req.url.searchParams.get('type') || '';

      const period = req.url.searchParams.get('period') || '';

      console.log('Mock API recebeu:', { page, type, period });

      const filterParams = {
        type,
        period
      };

      await new Promise(resolve => setTimeout(resolve, 300));

      const response = mockOrdersV2Responses.getFinishedOrdersResponse(
        page,
        filterParams
      );

      return res(ctx.status(200), ctx.json(response));
    }
  )
];

export const ordersV2Scenarios: Scenarios = {
  error: [
    rest.get(
      `${process.env.VUE_APP_BFF_URL}/transactions/v1/orders/portfolio/*/openned`,
      async (_, res, ctx) => {
        return res(
          ctx.status(500),
          ctx.json({
            message: 'Internal server error',
            code: 'INTERNAL_SERVER_ERROR'
          })
        );
      }
    ),
    rest.get(
      `${process.env.VUE_APP_BFF_URL}/transactions/v1/orders/portfolio/*`,
      async (_, res, ctx) => {
        return res(
          ctx.status(500),
          ctx.json({
            message: 'Internal server error',
            code: 'INTERNAL_SERVER_ERROR'
          })
        );
      }
    )
  ],
  empty: [
    rest.get(
      `${process.env.VUE_APP_BFF_URL}/transactions/v1/orders/portfolio/*/openned`,
      async (_, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({
            count: 0,
            items: []
          })
        );
      }
    ),
    rest.get(
      `${process.env.VUE_APP_BFF_URL}/transactions/v1/orders/portfolio/*`,
      async (_, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json({
            page: 1,
            pageSize: 5,
            count: 0,
            totalCount: 0,
            totalPages: 0,
            hasPreviousPage: false,
            hasNextPage: false,
            orders: [],
            periodFilter: [],
            filters: []
          })
        );
      }
    )
  ]
};
