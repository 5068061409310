/*
 ** Manter esse arquivo organizado por ordem alfabética
 */
export enum FeatureFlags {
  CS_CHAT = 'CS_CHAT',
  TRADE_3_0 = 'TRADE_3_0',
  DISABLE_WARREN_BUILDER = 'DISABLE_WARREN_BUILDER',
  PLATFORM_MAINTENANCE = 'br.com.warren.foundation.platform-maintenance',
  TRADE_ENABLE_NEW_BROKER_CLIENT_FLOW = 'br.com.warren.trade.enable-new-broker-client-flow',
  ENABLE_QUARTERLY_COSTS = 'br.com.warren.experience.enable-quarterly-cost-statement',
  ENABLE_PLANNED_ALLOCATION = 'br.com.warren.experience.enable-planned-allocation',
  ENABLE_CATALOG_CUSTOM_OPERATIONS = 'br.com.warren.experience.enable-catalog-custom-operations',
  ENABLE_SECURITIES_LENDING = 'br.com.warren.experience.enable-securities-lending',
  ENABLE_ORDERS = 'br.com.warren.experience.enable-new-managed-portfolios-orders-list'
}
