export enum EButtonType {
  SUGGESTIONS = 'suggestions',
  IN_PORTABILITY = 'incoming-portability',
  OUT_PORTABILITY = 'outgoing-portability',
  REGISTER = 'register'
}

export interface IButtonData {
  icon: string;
  total: number;
  label: string;
  enabled: boolean;
  type: EButtonType;
}

export interface IAccountDataCards {
  title: string;
  buttons: IButtonData[];
}
