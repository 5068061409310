import { Route, RouteConfig } from 'vue-router';
import { beforeEnterInvestmentFlow } from '@/router/helpers/beforeEnterInvestmentFlow';
import { beforeEnterInvestment } from '@/modules/common/routes/navigation-guards/investment';

import InvestmentTransactionView from '@/modules/transaction/views/InvestmentTransaction/InvestmentTransactionView.vue';
import PortfoliosToInvestView from '@/modules/transaction/views/PortfoliosToInvest/PortfoliosToInvestView.vue';
import NewTransferView from '@/modules/transaction/views/NewTransfer/NewTransferView.vue';
import TransactionsView from '@/modules/transaction/views/Transactions/TransactionsView.vue';
import VariableIncomeInvestment from '@/modules/transaction/views/VariableIncomeInvestment/VariableIncomeInvestment.vue';
import VariableIncomeWithdraw from '@/modules/transaction/views/VariableIncomeWithdraw/VariableIncomeWithdraw.vue';
import ProductInvestment from '@/modules/transaction/views/ProductInvestment/ProductInvestment.vue';
import ProductWithdraw from '@/modules/transaction/views/ProductWithdraw/ProductWithdraw.vue';
import CustomOperationsProductInvestment from '../views/CustomOperations/ProductInvestment/CustomOperationsProductInvestment.vue';

export const transactionsRoutes: RouteConfig[] = [
  {
    name: 'new-transfer',
    path: '/new-transfer',
    component: NewTransferView,
    meta: {
      context: 'transactions'
    }
  },
  {
    name: 'investment-transaction',
    path: '/investment-transaction/:portfolio',
    component: InvestmentTransactionView,
    beforeEnter: beforeEnterInvestmentFlow,
    props: true,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'portfoliosToInvest',
    path: '/portfolios-to-invest',
    beforeEnter: beforeEnterInvestmentFlow,
    component: PortfoliosToInvestView,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'transactions',
    path: '/transactions',
    component: TransactionsView,
    props: ({ params }: Route) => ({
      transactionsStep: params.transactionsStep
    }),
    meta: {
      context: 'cash'
    }
  },
  {
    name: 'variableIncomeInvestment',
    path: '/investment/variable-income/:id',
    beforeEnter: beforeEnterInvestment,
    component: VariableIncomeInvestment,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'variableIncomeWithdraw',
    path: '/withdraw/variable-income/:id',
    component: VariableIncomeWithdraw,
    meta: {
      context: 'wealth'
    }
  },
  {
    name: 'productInvestment',
    path: '/products-view/catalog/:type/:id/investment',
    component: ProductInvestment,
    meta: {
      context: 'products'
    }
  },
  {
    name: 'customOperationsProductInvestment',
    path: '/products-view/catalog/:type/:id/custom-op-investment',
    component: CustomOperationsProductInvestment,
    meta: {
      context: 'products'
    }
  },
  {
    name: 'productWithdraw',
    path: '/products-view/catalog/:type/:id/withdraw',
    component: ProductWithdraw,
    meta: {
      context: 'products'
    }
  }
];
