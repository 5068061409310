import { DateFormatted, NumberFormatted } from '@/modules/trade/types';

export interface IDashStatusCard {
  title?: string | null | undefined;
  message?: string | null | undefined;
  button?: string | null | undefined;
  action?: string | null | undefined;
  image?: {
    web?: IDashStatusImage;
  };
}

export interface IDashStatusImage {
  full?: string | null | undefined;
  regular?: string | null | undefined;
  small?: string | null | undefined;
}

export interface IDashButtons {
  action: string;
  label: string;
  icon: string;
  badge?: string;
  type: string;
}

export interface IDashStatus {
  card: IDashStatusCard;
  buttons: IDashButtons[];
}

export interface Operations {
  description: string;
  operationDate: {
    formatted: string;
  };
  value: NumberFormatted;
  composition: string;
}

export interface ProjectedBalances {
  value: NumberFormatted;
  date: NumberFormatted;
  label: string;
  operations: Operations[];
}

export interface GrossBalanceDetails extends NumberFormatted {
  updatedAt: NumberFormatted;
}

export interface HomeBrokerStatus {
  buttonText: string;
  status: TradeHomeBrokerStatus;
}

export interface BrokerageFee {
  title: string;
  text: string;
  buttonLabel: string;
  date: DateFormatted;
  link: string;
}

export interface AccountStatus {
  isRLPEnabled: boolean;
  enabledMFA: boolean;
  enabledBuy: boolean;
  portfolioApiId: string;
  tradingAvailable: boolean;
  homeBroker: HomeBrokerStatus;
  marketAvailable: {
    isMarketAvailable: boolean;
    marketStatus: {
      value: string;
      formatted: string;
    };
    description: string;
    innerDescription: string;
    negotiationAvailable?: {
      buy: {
        action: string;
      };
      sell: {
        action: string;
      };
    };
  };
  enableBrokerageFee: BrokerageFee;
}

export enum TradeHomeBrokerStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Requested = 'Requested'
}

export enum HomeChartDateFiltersKeys {
  Default = 'DEFAULT',
  OneDay = 'ONE_DAY',
  SevenDays = 'SEVEN_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
  SixMonths = 'SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS'
}

export interface HomeChartDateFilters {
  key: HomeChartDateFiltersKeys | string;
  description: string;
}

export interface HomeChartBenchmarkFilters {
  key: string;
  description: string;
}

export enum HomeChartAxisYKeys {
  Portfolio = 'PORTFOLIO',
  TotalBalance = 'TOTAL_BALANCE',
  Ibovespa = 'IBOVESPA'
}

export interface IbenchmarkComparison {
  formatted: string;
  label: string;
  value: number;
}

export interface HomeChartAxisYValue {
  value: number;
  formatted: string;
  benchmarkComparison: IbenchmarkComparison[];
}

export interface HomeChartAxisY {
  key: HomeChartAxisYKeys | string;
  description: string;
  values: HomeChartAxisYValue[];
}

export enum HomeChartAxisXKeys {
  Dates = 'DATES'
}

export interface HomeChartAxisX {
  key: HomeChartAxisXKeys | string;
  description: string;
  values: string[];
}

export interface HomeChartData {
  filters: HomeChartDateFilters[];
  benchmarkFilters: HomeChartBenchmarkFilters[];
  defaultFilter: number;
  defaultBenchmarkFilter: number;
  axisY: HomeChartAxisY[];
  defaultAxisY: number;
  axisX: HomeChartAxisX[];
  defaultAxisX: number;
  totalProfits: NumberFormatted;
  updatedAt: NumberFormatted;
  lastTotalBalance: NumberFormatted;
}

export interface GetHomeChartResponse {
  data: HomeChartData;
}

interface IButtonAction {
  type: string;
}

interface IButton {
  label: string;
  icon: string;
  enabled: boolean;
  isNew: boolean;
  action: IButtonAction;
}

interface IBalance {
  value: number;
  formatted: string;
}

interface IAccountDetail {
  accountBalance: IBalance;
  liquidatedBalance: IBalance;
  availableBalance: IBalance;
}

interface IAccount {
  availableBalance: IBalance;
  detail: IAccountDetail;
}

interface IMarginStockDetails {
  title: string;
  description: string;
}

interface IMarginStock {
  label: string;
  amount: IBalance;
  details: IMarginStockDetails;
}

interface ITotalBalanceUpdatedAt {
  value: number;
  formatted: string;
}

export interface IHeroBalances {
  totalBalance: IBalance;
  totalBalanceUpdatedAt: ITotalBalanceUpdatedAt;
  buttons: IButton[];
  account: IAccount;
  marginStock: IMarginStock;
}
