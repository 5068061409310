
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { BackButton } from '@/modules/common/components/buttons/BackButton';
import {
  NavigationPanelWrapper,
  NavigationPanel
} from '@/modules/common/components/NavigationPanel';
import { WatchListInfiniteScroll } from './components/WatchListInfiniteScroll';
import { GeneralSidebarList } from './components/GeneralSidebarList';
import { SidebarTypes } from './enums';

import {
  TradePosition,
  TradeInstrumentPositionPrice
} from '@/modules/trade/types/instrument';
import { Instrument as InstrumentWithWatchlist } from '@/modules/trade/services/search';

import { SignalR, MarketData } from '@/modules/trade/services/signalr';
import {
  AccountStatus,
  IHeroBalances,
  HomeBrokerStatus
} from '@/modules/trade/services/account';

const customerModule = namespace('CustomerModule');
const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    BackButton,
    NavigationPanel,
    NavigationPanelWrapper,
    WatchListInfiniteScroll,
    GeneralSidebarList
  }
})
export default class TradeSidebar extends Vue {
  @Prop({ type: Object, required: false })
  readonly balances?: IHeroBalances;

  @Prop({ type: Object, required: false })
  readonly tradeStatus?: AccountStatus;

  @Prop({ type: Function, default: () => {} })
  readonly onAccessHomeBrokerClick!: Function;

  @Prop({ type: Function, default: () => {} })
  readonly openBrokerModal!: Function;

  @Prop({ type: Object, default: null })
  readonly homeBrokerStatus!: HomeBrokerStatus | null;

  @tradePositionsModule.Getter('getPositions')
  readonly positions!: TradePosition[];

  @tradePositionsModule.Getter('getWatchlist')
  readonly watchlist!: InstrumentWithWatchlist[];

  @tradePositionsModule.Mutation('updateWatchlist')
  readonly updateWatchlist!: (data: MarketData) => void;

  @tradePositionsModule.Mutation('updatePosition')
  readonly updatePosition!: (data: TradeInstrumentPositionPrice) => void;

  @customerModule.Getter('getCustomerId') readonly customerId!: string;

  private sidebarTypes = SidebarTypes;
  private isWatchlistInvoked = false;

  created() {
    this.startSignalR();
  }

  destroyed() {
    this.stopSignalR();
  }

  private async startSignalR() {
    await SignalR.startConnection();

    SignalR.connection.on('MarketData', (data: MarketData) => {
      this.updateWatchlist(data);
    });

    SignalR.connection.onreconnected(async () => {
      this.isWatchlistInvoked = false;

      await this.initiateWatchListSubscription();
    });
  }

  private async stopSignalR() {
    await SignalR.stopConnection();
  }

  async initiateWatchListSubscription() {
    if (Array.isArray(this.watchlist) && this.watchlist.length !== 0) {
      if (!this.isWatchlistInvoked) {
        const watchlistSymbols = this.watchlist.map(
          watchlist => watchlist.symbol
        );
        await SignalR.invokeWithSubscription('JoinGroup', watchlistSymbols);
        this.isWatchlistInvoked = true;
      }
    }
  }
}
