import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { RiskCell } from '@/modules/catalog/components';

const productLabel = (products: number) => {
  return products > 1 ? `${products} Produtos` : `${products} Produto`;
};

export const columns = (
  products: number,
  isMarketOpen: boolean
): DataTableProps.Column[] => {
  return [
    {
      label: productLabel(products),
      sort: 'Name',
      width: '25%',
      getter: ({ name, category }) => ({
        text: name,
        description: `${category}${isMarketOpen ? '' : ' Fechado'}`
      })
    },
    {
      label: 'Mínimo inicial',
      align: DataTableProps.Align.Right,
      sort: 'MinimumInvestment',
      width: '15%',
      getter: 'minimumInvestment'
    },
    {
      label: 'Vencimento',
      align: DataTableProps.Align.Right,
      sort: 'MaturityDate',
      width: '15%',
      getter: 'maturityDate'
    },
    {
      label: 'Carência',
      align: DataTableProps.Align.Right,
      sort: 'GracePeriodDays',
      width: '15%',
      getter: 'liquidity'
    },
    {
      label: 'Taxa (a.a)',
      align: DataTableProps.Align.Right,
      width: '15%',
      getter: 'interestRate'
    },
    {
      label: 'Risco',
      component: RiskCell,
      align: DataTableProps.Align.Right,
      width: '15%',
      sort: 'RiskScoreType.Id',
      getter: ({ risk }) => ({
        risk
      })
    }
  ];
};
