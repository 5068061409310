import { httpClient } from '@/modules/common/services/http/http-client';
import {
  NotificationCounterData,
  NotificationData,
  NotificationQueryParams
} from './types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/notification`
});

export const getNotificationsCounter = (): Promise<NotificationCounterData> => {
  return http.get('v1/customer/push/pending-reads');
};

export const getNotifications = (
  queryParams: NotificationQueryParams
): Promise<NotificationData> => {
  const queryString = Object.keys(queryParams)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join('&');
  return http.get(`v1/customer/push?${queryString}`);
};

export const postReadNotifications = () => {
  http.put('v1/customer/push/mark-all-as-read');
};
