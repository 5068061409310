import { IInvestmentDetails } from '@/modules/transaction/services/product-investment';

export enum EProductTypes {
  FIXED_INCOME = 'fixed-income',
  GOVERNMENT_BOND = 'government-bond',
  FUNDS = 'funds'
}

export function errorText(
  data: IInvestmentDetails,
  inputValue: number,
  correctedValue: boolean
) {
  const { minimumInvestment, accountBalance, increment } = data;

  if (inputValue > accountBalance.value) {
    return 'Valor maior que o saldo em conta';
  }

  if (inputValue > 0 && inputValue < minimumInvestment.value) {
    return 'Valor menor que o mínimo para investir';
  }

  if (correctedValue) {
    return `O valor precisa ser múltiplo de ${increment.formatted}. Já arredondamos ele para você`;
  }

  return '';
}

export function errorStatus(data: IInvestmentDetails, inputValue: number) {
  const { minimumInvestment, accountBalance } = data;

  if (inputValue > accountBalance.value) {
    return 'error';
  }

  if (inputValue > 0 && inputValue < minimumInvestment.value) {
    return 'error';
  }

  return '';
}

export function listDetails(
  data: IInvestmentDetails,
  productType: string,
  quantity: number,
  loading: boolean
) {
  const { minimumInvestment, investmentWindow, unitPrice, currentBalance } =
    data;

  const list = [
    {
      label: currentBalance?.label || '',
      value: currentBalance?.formatted || '',
      loading: false,
      show: Boolean(currentBalance)
    },
    {
      label: 'Quantidade',
      value: String(quantity),
      loading,
      show: Boolean(productType !== EProductTypes.FUNDS)
    },
    {
      label: minimumInvestment.label,
      value: minimumInvestment.formatted,
      loading: false,
      show: Boolean(minimumInvestment)
    },
    {
      label: unitPrice?.label || '',
      value: unitPrice?.formatted || '',
      loading: false,
      show: Boolean(unitPrice)
    },
    {
      label: investmentWindow.label,
      value: investmentWindow.value,
      loading: false,
      show: Boolean(investmentWindow)
    }
  ];

  return list.filter(item => item.show);
}
