
import { Component, Vue } from 'vue-property-decorator';
import {
  Heading,
  HelperView,
  ButtonBoxIcon,
  InitialAvatarImage,
  SpinnerLoader,
  BaseText
} from '@warrenbrasil/nebraska-web';
import { InvestmentTransactionNavbar } from '@/modules/transaction/views/InvestmentTransaction/components/InvestmentTransactionNavbar';
import { useBankList } from '../../composables/useBankList';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Heading,
    InvestmentTransactionNavbar,
    ButtonBoxIcon,
    InitialAvatarImage,
    SpinnerLoader,
    HelperView,
    BaseText
  }
})
export default class WealthProductWithdrawDestination extends Vue {
  public useBankListRef = useBankList();
  public bankList = this.useBankListRef.bankList.value;
  private NebraskaColors = NebraskaColors;

  hasError = false;
  isLoading = false;

  created() {
    this.useBankListRef.toggleBankId('');
  }

  public onBackClick() {
    this.$router.back();
  }

  public onSelectBank(bankId?: string) {
    if (bankId) {
      this.useBankListRef.toggleBankId(bankId);
    }

    return this.$router.push({
      name: 'wealthPortfolioWithdrawReview'
    });
  }

  get formattedBankList() {
    if (!this.bankList) return [];

    return [
      {
        label: this.bankList.warrenBankAccount.label,
        bankAccounts: [this.bankList.warrenBankAccount.bankAccount]
      },
      {
        label: this.bankList.externalBankAccounts.label,
        bankAccounts: this.bankList.externalBankAccounts.bankAccounts
      }
    ];
  }
}
