
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';

import { PortfoliosToInvestCardLoader } from '../PortfoliosToInvestCardLoader';

import { Notification } from '@/modules/common/components/Notification';
import { WarrenModules } from '@/types/navigation';
import { trackHomeByAction } from '@/modules/home/helpers/tracking';
import { PortfolioCard } from '@/modules/wealth/views/Wealth/components/PortfolioCard';
import { GoalType } from '@/types/models/Portfolio';

import { IPortfolio } from '@/modules/wealth/services/investment';

import {
  userCanPerform,
  Operation,
  isImpersonate
} from '@/modules/common/services/partner';

@Component({
  name: 'PortfoliosToInvestPortfolioList',
  components: {
    PortfoliosToInvestCardLoader,
    Notification,
    PortfolioCard
  }
})
export default class PortfoliosToInvestPortfolioList extends Vue {
  @Prop({ type: Array, required: true })
  private portfolios!: IPortfolio[];

  @Prop({ type: Boolean, required: true })
  private isLoading!: boolean;

  @Emit('tracking')
  private trackPortfolioClick(portfolioName: string) {
    return portfolioName;
  }

  public async portfolioClick(portfolio: IPortfolio) {
    if (this.isPortfolioDisabled(portfolio)) return;
    const { cameFrom } = this.$route.query;
    if (cameFrom === WarrenModules.Home)
      trackHomeByAction('Investir \\ Carteira existente');
    this.trackPortfolioClick(portfolio.name);

    if (portfolio.isCustom) {
      this.gotToProductInvestment(portfolio.id);
    } else {
      this.gotToPortfolioInvestment(portfolio.id);
    }
  }

  private gotToProductInvestment(id: string) {
    this.$router.push({
      name: 'wealthProductTransaction',
      params: { id }
    });
  }

  private gotToPortfolioInvestment(portfolio: string) {
    this.$router.push({
      name: 'investment-transaction',
      params: { portfolio }
    });
  }

  public isPortfolioDisabled(portfolio: IPortfolio) {
    if (!isImpersonate()) return !portfolio.canInvestInPortfolio;

    if (!portfolio.isCustom) return !portfolio.canInvestInPortfolio;

    if (portfolio.goal === GoalType.PRIVATE_PENSION)
      return !portfolio.canInvestInPortfolio;

    return (
      !userCanPerform(Operation.DepositByProduct) ||
      !portfolio.canInvestInPortfolio
    );
  }
}
