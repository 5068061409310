import { httpClient } from '@/modules/common/services/http/http-client';
import { ITransactionFilters } from './banking-types';

const http = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BANKING_API_URL
});

export function getTransactionsReport(
  filters: ITransactionFilters
): Promise<BlobPart> {
  return http.get(`v1/cash/getTransactionsReport`, {
    params: filters,
    responseType: 'blob'
  });
}

export function deleteScheduledTransaction(
  transactionId: string
): Promise<void> {
  return http.delete(`v1/statement/scheduled/${transactionId}`);
}
