import { httpClient } from '@/modules/common/services/http/http-client';

import {
  IDashStatus,
  IHeroBalances,
  AccountStatus,
  GetHomeChartResponse,
  HomeChartDateFiltersKeys
} from './types';

export const httpClientWithDefaultOptions = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_URL
});

export function getDashStatus(): Promise<IDashStatus> {
  return httpClientWithDefaultOptions.get(`/trade/v1/dashStatus`);
}

export function getBalances(): Promise<IHeroBalances> {
  return httpClientWithDefaultOptions.get('/trading/v1/hero/balances');
}

export async function getChart(
  filter: HomeChartDateFiltersKeys | null | string,
  benchmark: string | null
): Promise<GetHomeChartResponse> {
  return httpClientWithDefaultOptions.get('/trade/v1/homeChart', {
    params: {
      filter,
      benchmarkFilter: benchmark
    }
  });
}

export function getAccountStatus(): Promise<AccountStatus> {
  return httpClientWithDefaultOptions.get('/trade/v1/accountStatus');
}
