import { StorageKeys } from '@/modules/common/services/local-storage';
import {
  getThemeByParam,
  impersonate
} from '@/modules/common/services/http/account';

export async function impersonateCustomer(customerId: string) {
  const storageDataAccess: string =
    localStorage.getItem(StorageKeys.ImpersonateToken) || '';
  if (storageDataAccess) localStorage.removeItem(StorageKeys.ImpersonateToken);

  const dataAccess = await impersonate(customerId);
  if (!dataAccess) return 'error';

  localStorage.setItem(StorageKeys.ImpersonateToken, dataAccess.accessToken);
  localStorage.setItem(StorageKeys.CustomerId, customerId);
  localStorage.setItem(StorageKeys.ForceWrnUserUpdate, 'true');
  localStorage.setItem(StorageKeys.WhitelabelTheme, 'whitelabel');

  const partner = localStorage.getItem(StorageKeys.Partner);
  const partnerEmail = JSON.parse(partner || '{}').email;
  const theme = await getThemeByParam(
    'email',
    partnerEmail || '',
    StorageKeys.ImpersonateToken
  );

  localStorage.setItem(StorageKeys.Theme, JSON.stringify(theme));
}

export function openImpersonatedWindow(url?: string) {
  const pathToRedirect = 'app/#/v3/overview';
  const impersonatedDashUrl = `${window.location.protocol}//${window.location.host}/${pathToRedirect}`;
  const impersonateWindow = window.open(
    url ?? impersonatedDashUrl,
    '_blank'
  ) as Window;

  impersonateWindow.isImpersonateWindow = true;
}
