
import {
  Component,
  Vue,
  Emit,
  Prop,
  InjectReactive
} from 'vue-property-decorator';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { WarrenModules } from '@/types/navigation';
import { WealthChartAction } from '@/modules/wealth/components/WealthChartAction';
import {
  getWealthActionButtons,
  IActionButton
} from '@/modules/wealth/services/wealth-actions';
import { SkeletonLoader } from '@warrenbrasil/nebraska-web';
import { trackWealthWithdraw } from '../../track-events';

@Component({
  components: { WealthChartAction, SkeletonLoader }
})
export default class WealthViewActions extends Vue {
  @Prop({ type: Boolean, required: true })
  private isCustomerOverdue!: boolean;

  private isLoading = false;

  private isError = false;

  private actionButtonsBff: IActionButton[] = [];

  private actionButtons = [
    {
      title: 'Criar carteira',
      icon: 'EF0010',
      isEnabled: !this.isDisabledWarrenBuilder,
      action: this.emitCreatePortfolioEvent,
      style: 'primary',
      testId: 'create-wealth'
    },
    {
      title: 'Investir',
      icon: 'BA0010',
      isEnabled: true,
      action: this.goToInvestmentFlow,
      style: 'secondary',
      testId: 'invest'
    }
  ];

  @Emit('create-portfolio')
  private emitCreatePortfolioEvent() {}

  @Emit('open-investment')
  private emitInvestmentEvent() {}

  @InjectReactive('shouldTriggerHomeAnimations')
  public addLoadedClass!: boolean;

  created() {
    this.withdrawButton();
  }

  get isDisabledWarrenBuilder() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.DISABLE_WARREN_BUILDER);
  }

  buttonsMapper() {
    if (this.hasWithdrawButton)
      this.actionButtons.push({
        title: this.actionButtonsBff[0].title,
        icon: 'EA0050',
        isEnabled: true,
        action: this.goToWithdrawFlow,
        style: 'secondary',
        testId: 'withdraw'
      });
  }

  get hasWithdrawButton() {
    return this.actionButtonsBff.length > 0;
  }

  private async withdrawButton() {
    try {
      this.isLoading = true;
      const response = await getWealthActionButtons();
      this.actionButtonsBff = response.wealthButtons.data;
      this.buttonsMapper();
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  goToInvestmentFlow() {
    this.emitInvestmentEvent();

    if (this.isCustomerOverdue) {
      return this.$router.push({
        name: 'regulatoryUpdateAnnouncement'
      });
    }

    this.$router.push({
      name: 'portfoliosToInvest',
      query: { cameFrom: WarrenModules.Wealth }
    });
  }

  goToWithdrawFlow() {
    trackWealthWithdraw();
    this.$router.push({
      name: 'wealthWithdrawSelection',
      query: { cameFrom: WarrenModules.Wealth }
    });
  }
}
