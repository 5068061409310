
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import { RouteMixin } from '@/modules/common/mixins/route';
import { SkeletonLoader, ButtonBoxLaunch } from '@warrenbrasil/nebraska-web';
import { ILegend } from '@/modules/common/components/charts/LegendChart';
import {
  IDashButtons,
  getChart,
  IHeroBalances,
  HomeChartDateFilters,
  HomeChartBenchmarkFilters,
  HomeChartData,
  HomeChartAxisY,
  HomeChartAxisX,
  HomeChartAxisYKeys
} from '@/modules/trade/services/account';
import { moneyViewer as money } from '@/modules/common/helpers/currency';
import { MainError } from '@/modules/trade/components/MainError';
import { MainLoading } from '@/modules/trade/components/MainLoading';

import {
  ChartNoData,
  ChartFilters,
  LineChart,
  ChartFooter
} from '@/modules/common/components/charts/v2';
import {
  ChartFilters as ChartFiltersV2,
  ChartFiltersTypes,
  ChartFilterValue,
  ChartFiltersKeys
} from './components/ChartFilters';
import { getAxisXLabelDateFormatByFilter } from './helpers';

import {
  trackChartFilterClick,
  trackSearchButtonClick,
  trackTradeWithdrawClick,
  trackChartRequestError,
  trackChartRetryClick
} from '@/modules/trade/views/TradeView/trackers';

import { DashStatusButtonActions } from '@/modules/trade/types/dash-status';

import { ChartSeries } from './types';
import { filtersPortfolioApiIdDependentButtons } from '@/modules/trade/views/TradeView/helpers';
import { RequestStatus } from '@/modules/trade/store/trade';
import { CustomOperationSummary } from '@/modules/trade/services/custom-operations';
import { logError } from '@/modules/trade/helpers/logs';

const tradePositionsModule = namespace('tradeModule');

@Component({
  filters: {
    money
  },
  components: {
    ChartFilters,
    LineChart,
    MainError,
    SkeletonLoader,
    ChartFooter,
    MainLoading,
    ButtonBoxLaunch,
    ChartFiltersV2,
    ChartNoData
  }
})
export default class Chart extends mixins(RouteMixin) {
  @Prop({ type: Object, required: false })
  readonly balances?: IHeroBalances;

  @Prop({ type: Array, required: false, default: () => [] })
  readonly accountButtons?: IDashButtons[];

  @Prop({ type: Boolean, default: false })
  readonly isCustomerBlocked!: boolean;

  @tradePositionsModule.State('balancesBrokerRequestStatus')
  readonly balancesBrokerRequestStatus!: RequestStatus;

  @tradePositionsModule.State('dashStatusRequestStatus')
  readonly dashStatusRequestStatus!: RequestStatus;

  @tradePositionsModule.Action('fetchDashStatus')
  readonly fetchDashStatus!: Function;

  @tradePositionsModule.State('customOperations')
  readonly customOperations!: CustomOperationSummary[];

  readonly RequestStatus = RequestStatus;
  readonly DashStatusButtonActions = DashStatusButtonActions;
  readonly ChartFiltersKeys = ChartFiltersKeys;
  loading = true;
  isError = false;
  series: ChartSeries[] = [];
  labels: string[] = [];
  filters: ILegend[] = [];
  labelDateFormat = '';
  private seriesValues!: ChartSeries[];
  private lastTotalBalance!: number;
  private isChartAnimationInProgress = false;
  private chartData: HomeChartData | null = null;

  chartFilters: ChartFiltersTypes = {
    [ChartFiltersKeys.DATE_FILTER]: {
      value: null,
      options: []
    },
    [ChartFiltersKeys.BENCHMARK_FILTER]: {
      value: null,
      options: []
    }
  };

  created() {
    this.fetchesChartData();
  }

  handleOnAnimationStateChange(isAnimationInProgress: boolean) {
    this.isChartAnimationInProgress = isAnimationInProgress;
  }

  get heightChart() {
    const height = window.innerHeight;
    if (height >= 1000) return '400px';
    else if (height >= 900) return '380px';
    else if (height >= 721) return '280px';
    else if (height > 650) return '190px';
    else return '150px';
  }

  get hasCustomOperations() {
    return this.customOperations?.length > 0;
  }

  get isChartEmpty() {
    return (
      !this.seriesData ||
      !this.seriesData.data ||
      this.seriesData.data.length === 0
    );
  }

  get buttons() {
    const accountButtons = this.accountButtons || [];

    // Se o serviço de buscar o saldo teve erro, a gente não tem o portfolioApiId
    return this.balancesBrokerRequestStatus === RequestStatus.Error
      ? filtersPortfolioApiIdDependentButtons(accountButtons)
      : accountButtons;
  }

  public buttonAction(button: IDashButtons) {
    switch (button.action) {
      case DashStatusButtonActions.Search:
        trackSearchButtonClick();
        this.$router.push({ name: 'tradeSearch' });
        break;
      case DashStatusButtonActions.Withdrawal:
        trackTradeWithdrawClick();
        break;
      case DashStatusButtonActions.BrokerageNotes:
        this.$router.push({ name: 'tradingNotes', query: { module: 'trade' } });
        break;
      default:
        break;
    }
  }

  get startDate() {
    return this.labels[0];
  }

  get endDate() {
    return this.labels[this.labels.length - 1];
  }

  get seriesData() {
    const [series] = this.series;

    return {
      ...series,
      comparator: []
    };
  }

  get benchmarkData() {
    return this.series[1];
  }

  get hasBalance() {
    const hasGrossBalance =
      this.balances?.totalBalance?.value &&
      this.balances.totalBalance.value > 0;

    return hasGrossBalance;
  }

  get canIgnoreDashStatusRequest() {
    return !!(
      this.dashStatusRequestStatus === RequestStatus.Default ||
      (this.dashStatusRequestStatus === RequestStatus.Error && this.hasBalance)
    );
  }

  get errorTitle() {
    return this.canIgnoreDashStatusRequest
      ? 'Não foi possível carregar o gráfico'
      : 'Não foi possível carregar os dados da sua conta';
  }

  get canRenderLineChart() {
    return !this.isError && this.canIgnoreDashStatusRequest && this.chartData;
  }

  async handleOnRetry() {
    trackChartRetryClick();

    this.canIgnoreDashStatusRequest
      ? await this.fetchesChartData()
      : await this.fetchDashStatus();
  }

  private buildSeriesWithFinOneData(axisY: HomeChartAxisY[]) {
    const series = [];

    const mainSeries = axisY.find(
      data => data.key === HomeChartAxisYKeys.Portfolio
    );

    if (mainSeries)
      series.push({
        name: mainSeries.description,
        data: mainSeries.values.map(value => (value ? value.value : null)),
        benchmarkComparison: mainSeries.values.map(value =>
          value ? value.benchmarkComparison : {}
        )
      });

    if (this.chartFilters[ChartFiltersKeys.BENCHMARK_FILTER].value) {
      const benchmarkSeries = axisY.find(
        data =>
          data.key ===
          this.chartFilters[ChartFiltersKeys.BENCHMARK_FILTER].value
      );

      if (benchmarkSeries)
        series.push({
          name: benchmarkSeries.description,
          data: benchmarkSeries.values.map(value =>
            value ? value.value : null
          )
        });
    }

    return series;
  }

  private buildSeries(axisY: HomeChartAxisY[]): ChartSeries[] {
    const series = [];

    const portfolio = axisY.find(
      value => value.key === HomeChartAxisYKeys.Portfolio
    );
    if (portfolio) {
      series.push({
        name: 'Meus ativos',
        data: portfolio.values.map(value =>
          value ? value.value : null
        ) as number[],
        benchmarkComparison: portfolio.values.map(value =>
          value ? value.benchmarkComparison : {}
        )
      });
    }

    const benchmark = axisY.find(
      value => value.key === HomeChartAxisYKeys.Ibovespa
    );
    if (benchmark) {
      series.push({
        name: 'Ibovespa',
        data: benchmark?.values.map(value =>
          value ? value.value : null
        ) as number[]
      });
    }

    return series;
  }

  async fetchesChartData() {
    try {
      this.isError = false;
      this.loading = true;
      this.isChartAnimationInProgress = true;

      await this.fetchChartBff();
    } catch (error) {
      logError(error, 'request_chart_error');
      trackChartRequestError();
      this.isError = true;
      this.series = [];
    } finally {
      this.loading = false;
    }
  }

  private async fetchChartBff() {
    const { data } = await getChart(
      this.chartFilters[ChartFiltersKeys.DATE_FILTER].value,
      this.chartFilters[ChartFiltersKeys.BENCHMARK_FILTER].value
    );
    this.chartData = data;
    this.buildChartData();

    if (this.isChartEmpty) {
      this.handleOnAnimationStateChange(false);
    }
  }

  public buildValues(axisY: HomeChartAxisY[], valueType: string) {
    const typeValues = axisY.filter(value => value.key === valueType);
    const data = typeValues[0]?.values.map(value =>
      value ? value.value : null
    );

    if (!data) return [];

    return [
      {
        name: typeValues[0].description,
        data
      }
    ];
  }

  public buildLabels(axisX: HomeChartAxisX[]): string[] {
    return axisX[0].values;
  }

  private buildChartData() {
    this.buildChartFilters(this.chartData!);
    this.lastTotalBalance = this.chartData!.lastTotalBalance?.value;
    this.series = this.buildSeriesWithFinOneData(this.chartData!.axisY);
    this.labels = this.buildLabels(this.chartData!.axisX);
    this.seriesValues = this.buildValues(
      this.chartData!.axisY,
      HomeChartAxisYKeys.TotalBalance
    );
  }

  private buildChartFilters(chartData: HomeChartData) {
    const defaultDateFilter = this.getDefaultFilterValue(
      chartData?.defaultFilter,
      chartData.filters
    );
    this.changeChartFilterOptions(
      ChartFiltersKeys.DATE_FILTER,
      chartData.filters
    );
    if (!this.chartFilters[ChartFiltersKeys.DATE_FILTER].value) {
      this.changeChartFilterValue(
        ChartFiltersKeys.DATE_FILTER,
        defaultDateFilter
      );
    }

    const defaultBenchmarkFilter = this.getDefaultFilterValue(
      chartData?.defaultBenchmarkFilter,
      chartData.benchmarkFilters || []
    );
    this.changeChartFilterOptions(
      ChartFiltersKeys.BENCHMARK_FILTER,
      chartData.benchmarkFilters || []
    );
    if (!this.chartFilters[ChartFiltersKeys.BENCHMARK_FILTER].value) {
      this.changeChartFilterValue(
        ChartFiltersKeys.BENCHMARK_FILTER,
        defaultBenchmarkFilter
      );
    }

    this.labelDateFormat = getAxisXLabelDateFormatByFilter(
      this.chartFilters[ChartFiltersKeys.DATE_FILTER].value
    );
  }

  private getDefaultFilterValue(
    defaultFilterIndex: number | null,
    options: HomeChartDateFilters[] | HomeChartBenchmarkFilters[]
  ) {
    return defaultFilterIndex || defaultFilterIndex === 0
      ? options[defaultFilterIndex]?.key
      : null;
  }

  private buildChartFiltersOptions(
    options: HomeChartDateFilters[] | HomeChartBenchmarkFilters[]
  ) {
    return options.map(option => ({
      value: option.key,
      label: option.description
    }));
  }

  private changeChartFilterOptions(
    filterKey: ChartFiltersKeys,
    options: HomeChartDateFilters[] | HomeChartBenchmarkFilters[]
  ) {
    this.chartFilters[filterKey].options =
      this.buildChartFiltersOptions(options);
  }

  private changeChartFilterValue(
    filterKey: ChartFiltersKeys,
    value: ChartFilterValue
  ) {
    this.chartFilters[filterKey].value = value;
  }

  async onSelectFilter(filterKey: ChartFiltersKeys, value: ChartFilterValue) {
    this.changeChartFilterValue(filterKey, value);
    this.trackFilterClick(filterKey);
    await this.fetchesChartData();
  }

  private trackFilterClick(filterKey: ChartFiltersKeys) {
    const filterClicked = this.chartFilters[filterKey].options.find(
      filter => filter.value === this.chartFilters[filterKey].value
    );
    const filter = filterClicked?.label || '';

    trackChartFilterClick(filter);
  }
}
