import { httpClient } from '@/modules/common/services/http/http-client';
import { IBankList } from './onboarding-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/onboarding`
});

export function getBankList(): Promise<IBankList> {
  return http.get(`account/bank-list`);
}
