import {
  Options,
  YAxisOptions,
  SeriesOptionsType,
  PlotColumnOptions,
  XAxisLabelsOptions,
  AxisLabelsFormatterContextObject,
  TooltipFormatterContextObject,
  TooltipOptions
} from 'highcharts';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { moneyFormat } from '@/modules/common/helpers/currency';

const textPrimaryColor = NebraskaColors.textPrimary;

function valueFormatter(value: number) {
  if (value >= 1000000) return `${value}M`;
  if (value >= 1000) return `${value}K`;
  return String(value);
}

function getPointersTooltip(_point: TooltipFormatterContextObject) {
  return `<tr></tr>`;
}

function getTotalTooltip(
  pointers: string[],
  context: TooltipFormatterContextObject
) {
  const totalValue = context.points!.reduce((acc, curr) => {
    return acc + curr.y;
  }, 0);

  return `<div>
            <p style="font-size: 14px; font-weight: 700; margin-bottom: 12px; color: ${textPrimaryColor};">
              ${context.x}
            </p>
            <table>
              <tbody>
                ${pointers.join('')}
                <tr>
                  <td style="padding: 0; color: ${textPrimaryColor};">Total</td>
                  <td style="padding: 0; padding-right: 12px;"></td>
                  <td style="padding: 0; font-weight: 400; font-size: 12px; color: ${textPrimaryColor}; text-align: right;">
                    ${moneyFormat(totalValue)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>`;
}

function configureTooltipFormatter(context: TooltipFormatterContextObject) {
  const pointers = context.points!.map(point => getPointersTooltip(point));
  return getTotalTooltip(pointers, context);
}

function getLabelsConfiguration(): XAxisLabelsOptions {
  return {
    style: {
      color: NebraskaColors.elementSecondary,
      fontSize: '12px',
      fontFamily: 'var(--font-text)'
    },
    autoRotationLimit: 0,
    y: 30
  };
}

function getColumnConfiguration(): PlotColumnOptions {
  return {
    stacking: 'normal',
    pointWidth: 23,
    pointPadding: 0,
    groupPadding: 0,
    borderWidth: 0,
    borderRadius: 3
  };
}

function getTooltipConfiguration(): TooltipOptions {
  return {
    shared: true,
    shadow: false,
    useHTML: true,
    borderWidth: 1,
    borderRadius: 8,
    padding: 16,
    borderColor: NebraskaColors.dividerPrimary,
    backgroundColor: NebraskaColors.backgroundSecondary,
    formatter: function () {
      return configureTooltipFormatter(this);
    }
  };
}

function getyAxisConfiguration(maxAxisY?: number): YAxisOptions {
  return {
    title: {
      text: ''
    },
    labels: {
      style: {
        color: NebraskaColors.textSecondary,
        fontSize: '12px',
        fontFamily: 'var(--font-text)'
      },
      formatter: (context: AxisLabelsFormatterContextObject) => {
        return valueFormatter(context.value as number);
      }
    },
    max: maxAxisY,
    gridLineColor: NebraskaColors.dividerPrimary,
    gridLineWidth: 1,
    gridLineDashStyle: 'Dash',
    opposite: false,
    maxPadding: 0.1
  };
}

export function configureBarChartOptions(
  series: SeriesOptionsType[],
  labels: string[],
  maxAxisY?: number
): Options {
  return {
    chart: {
      type: 'column',
      spacingTop: 15,
      backgroundColor: NebraskaColors.backgroundSecondary
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: labels,
      startOnTick: true,
      endOnTick: true,
      labels: getLabelsConfiguration()
    },
    yAxis: getyAxisConfiguration(maxAxisY),
    tooltip: getTooltipConfiguration(),
    plotOptions: {
      column: getColumnConfiguration()
    },
    series
  };
}

export function configureBarChartAllocationOptions(
  series: SeriesOptionsType[]
): Options {
  return {
    chart: {
      type: 'column',
      backgroundColor: NebraskaColors.backgroundSecondary,
      styledMode: true,
      spacing: [0, 0, 0, 0],
      margin: 0,
      marginTop: 10
    },
    legend: { enabled: false },
    title: { text: '' },
    credits: { enabled: false },
    tooltip: { enabled: false },
    xAxis: {
      labels: { enabled: false },
      lineWidth: 0,
      tickWidth: 0
    },
    yAxis: {
      title: { text: null },
      labels: { enabled: false },
      gridLineWidth: 0,
      lineWidth: 0
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          overflow: 'allow',
          crop: false
        }
      }
    },
    series
  };
}
