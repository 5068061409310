
import { Component, Prop } from 'vue-property-decorator';
import {
  HeadingDisplay,
  ContentDisplay,
  ButtonPrimary,
  ListDisplay,
  ConclusionStep
} from '@warrenbrasil/nebraska-web';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RouteMixin } from '@/modules/common/mixins/route';
import { mixins } from 'vue-class-component';
import { useLastReedemStore } from './../../store/redeemWithdrawPortfolio';
import { isImpersonate } from '@/modules/common/services/partner';

@Component({
  components: {
    HeadingDisplay,
    ContentDisplay,
    ButtonPrimary,
    ListDisplay,
    BackButton,
    ConclusionStep
  }
})
export default class WealthPortfolioWithdrawCompleted extends mixins(
  RouteMixin
) {
  @Prop({ type: String, required: true })
  private readonly portfolioId!: string;

  readonly lastReedemStore = useLastReedemStore();

  readonly NebraskaColors = NebraskaColors;

  beforeMount() {
    if (!this.hasRedeemStored) {
      this.handleNextClick();
    }
  }

  get infoCompletedWithdraw() {
    return this.lastReedemStore.wealthSuggestedSummary;
  }

  get hasRedeemStored() {
    return this.lastReedemStore && this.lastReedemStore.wealthSuggestedSummary;
  }

  public handleNextClick() {
    this.lastReedemStore.clearRedeemInformation();
    this.$router.push({
      name: 'wealthPortfolio',
      params: { id: this.portfolioId }
    });
  }

  get withdrawTitle() {
    return isImpersonate()
      ? 'Sugestão de resgate enviada'
      : 'Resgate solicitado';
  }
}
