import { getDomainAndPlatform } from '@/modules/common/helpers/onboarding/getDomainAndPlatform';
import { hasAccessToken } from '@/modules/common/services/access-token';
import { NavigationGuard } from 'vue-router';

export const authGuard: NavigationGuard = (to, _from, next) => {
  const isPublic = to.matched.some(
    record => record.meta.requiresAuth === false
  );
  const requiresAuth = !isPublic && !hasAccessToken();

  if (requiresAuth) {
    const { domain, platform } = getDomainAndPlatform();

    const currentUrl = window.location.href;
    const redirectUrl = `/onboarding/${domain}/${platform}/login?redirect=${encodeURIComponent(
      currentUrl
    )}`;
    window.location.href = redirectUrl;
  } else {
    next();
  }
};
