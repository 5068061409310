import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IAppliedAllocation,
  IAllocationParams,
  IPlannedAllocation
} from './types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/portfolios`
});

export function getAppliedAllocation(
  portfolioId: string,
  params?: IAllocationParams
): Promise<IAppliedAllocation> {
  return http.get(`v1/allocations/applied/${portfolioId}`, { params });
}

export function getPlannedAllocation(
  portfolioId: string,
  params?: IAllocationParams
): Promise<IPlannedAllocation> {
  return http.get(`v1/allocations/planned/${portfolioId}`, { params });
}
