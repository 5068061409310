import { render, staticRenderFns } from "./PlannedAllocation.vue?vue&type=template&id=4030b38b&scoped=true&"
import script from "./PlannedAllocation.vue?vue&type=script&lang=ts&setup=true&"
export * from "./PlannedAllocation.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./PlannedAllocation.vue?vue&type=style&index=0&id=4030b38b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4030b38b",
  null
  
)

export default component.exports