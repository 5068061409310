import {
  getCustomTheme,
  ICustomTheme
} from '@/modules/common/services/local-storage';

export function getDomainAndPlatform(theme?: ICustomTheme): {
  domain: string;
  platform: 'mobile' | 'desktop';
} {
  if (!theme) {
    theme = getCustomTheme() || undefined;
  }

  let domain = 'warren';
  if (theme?.brandDomain && theme.type && theme.type !== 'WarrenManaged') {
    domain = theme.brandDomain.toLowerCase();
  }

  const platform: 'mobile' | 'desktop' =
    window.innerWidth <= 768 ? 'mobile' : 'desktop';

  return { domain, platform };
}
