import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IInvestmentDetails,
  IInvestmentAmount,
  IRiskSignature,
  IInvestmentReview,
  ITermSignature,
  IInvestmentConclusion,
  ICustomOperationSelection,
  ICustomOperationInfoResponse
} from './product-investment-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/instruments`
});

export const httpBffInvestments = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export function getCustomOperationSelection(
  productId: string
): Promise<ICustomOperationSelection> {
  return http.get(`/v1/investments/custom-operations/${productId}/selection`);
}

export function getCustomOperationInfo(
  productId: string,
  quantity: number
): Promise<ICustomOperationInfoResponse> {
  return http.get('/v1/investments/instrument/custom-operations-info', {
    params: { productId, quantity }
  });
}

export function getInvestmentDetails(
  productId: string,
  instrumentType: string
): Promise<IInvestmentDetails> {
  return http.get(`/v1/investments/instrument/${productId}/info`, {
    params: { instrumentType }
  });
}

export function getInvestmentAmount(
  productId: string,
  amount: number
): Promise<IInvestmentAmount> {
  return http.post(`/v1/investments/instrument/amount`, {
    amount,
    productId
  });
}

export function getInvestmentReview(
  productId: string,
  amount: number,
  instrumentType: string
): Promise<IInvestmentReview> {
  return http.get(`/v1/investments/instrument/${productId}/review`, {
    params: { amount, instrumentType }
  });
}

export function getCustomOperationInvestmentReview(
  productId: string,
  quantity: number
): Promise<IInvestmentReview> {
  return http.get(`/v2/investments/instrument/${productId}/review`, {
    params: { quantity }
  });
}

export function makeInvestment(
  productId: string,
  amount: number
): Promise<IInvestmentConclusion> {
  return http.post(`/v1/investments/instrument`, {
    productId,
    amount
  });
}

export function makeCustomOperationInvestment(
  productId: string,
  amount: number,
  quantity: number,
  partnerApiId?: string
): Promise<IInvestmentConclusion> {
  return http.post(
    `/v1/investments/custom-operations/${productId}/investment`,
    {
      amount,
      quantity,
      partnerApiId
    }
  );
}

export function getPendingRiskSignature(
  productId: string,
  instrumentType: string
): Promise<IRiskSignature> {
  return http.get(`/v1/risks/pending-signatures`, {
    params: { productIds: [productId], instrumentType }
  });
}

export function getCustomOperationPendingRiskSignature(
  productId: string
): Promise<IRiskSignature> {
  return http.get(`/v1/risks/custom-operations-pending-signatures`, {
    params: { productId }
  });
}

export function getPendingTermSignature(
  productId: string,
  instrumentType: string
): Promise<ITermSignature> {
  return http.get(`/v1/terms/pending-signatures`, {
    params: { productIds: [productId], instrumentType }
  });
}

export function getCustomOperationPendingTermSignature(
  productId: string
): Promise<ITermSignature> {
  return http.get(`/v1/terms/custom-operations-pending-signatures`, {
    params: { productId }
  });
}

export function riskSignature(instrumentId: string): Promise<void> {
  return httpBffInvestments.post('/v1/riskSignature', {
    instrumentIds: [instrumentId]
  });
}

export function riskCustomOperationSignature(
  instrumentId: string
): Promise<void> {
  return http.post('/v1/risks/custom-operation-signature-risk', {
    instrumentId
  });
}

export function termsSignature(instrumentId: string): Promise<void> {
  return httpBffInvestments.post('/v1/termSignature', {
    instrumentIds: [instrumentId]
  });
}

export function riskCustomOperationTermsSignature(
  instrumentId: string
): Promise<void> {
  return http.post('/v1/terms/signature', {
    instrumentId
  });
}
