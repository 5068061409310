import { render, staticRenderFns } from "./WealthPortfolioWithdrawDestination.vue?vue&type=template&id=4b40a343&scoped=true&"
import script from "./WealthPortfolioWithdrawDestination.vue?vue&type=script&lang=ts&"
export * from "./WealthPortfolioWithdrawDestination.vue?vue&type=script&lang=ts&"
import style0 from "./WealthPortfolioWithdrawDestination.vue?vue&type=style&index=0&id=4b40a343&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b40a343",
  null
  
)

export default component.exports