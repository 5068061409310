import router from '@/router';
import { RegulatoryUpdateNavigationAction } from '@/modules/account/views/RegulatoryUpdate/types';
import { StorageKeys } from '@/modules/common/services/local-storage';
import { getDomainAndPlatform } from '../../helpers/onboarding/getDomainAndPlatform';
export interface IRegulatoryUpdateRoutesOrchestrator {
  nextRoutes: string[];
  currentRoute: number;
}

function getMapActionToRoute() {
  return {
    [RegulatoryUpdateNavigationAction.RegulatoryRegisterUpdate]:
      '/regulatory-update/personal-data',
    [RegulatoryUpdateNavigationAction.Suitability]: '/suitability',
    [RegulatoryUpdateNavigationAction.Home]: '/home',
    [RegulatoryUpdateNavigationAction.Password]:
      '/regulatory-update/verify-password',
    [RegulatoryUpdateNavigationAction.Success]: '/regulatory-update/success',
    [RegulatoryUpdateNavigationAction.Security]: '/security',
    [RegulatoryUpdateNavigationAction.RegulatoryTerms]:
      '/regulatory-update/terms',
    [RegulatoryUpdateNavigationAction.PersonalRegisterUpdate]:
      '/regulatory-update/personal-register'
  };
}

export function getRegulatoryUpdateRoutes(): IRegulatoryUpdateRoutesOrchestrator {
  return JSON.parse(
    localStorage.getItem(StorageKeys.RegulatoryUpdateRoutes) || '{}'
  );
}

function setRegulatoryUpdateRoutes(nextRoutes: string[], currentRoute: number) {
  localStorage.setItem(
    StorageKeys.RegulatoryUpdateRoutes,
    JSON.stringify({
      nextRoutes,
      currentRoute
    })
  );
}

export function saveNextRoutes(routes: RegulatoryUpdateNavigationAction[]) {
  setRegulatoryUpdateRoutes(
    routes.map(route => getMapActionToRoute()[route]),
    -1
  );
}

export function goToNextRoute() {
  const { currentRoute, nextRoutes } = getRegulatoryUpdateRoutes();
  const route = nextRoutes[currentRoute + 1];

  if (route === '/suitability') {
    setRegulatoryUpdateRoutes(nextRoutes, currentRoute + 2);
    redirectToSuitability({ backlink: nextRoutes[currentRoute + 2] });
  } else {
    setRegulatoryUpdateRoutes(nextRoutes, currentRoute + 1);
    router.push({ path: route });
  }
}

function redirectToSuitability({ backlink }: { backlink: string }) {
  const { domain, platform } = getDomainAndPlatform();

  window.location.href = `/onboarding/${domain}/${platform}/suitability?backlink=${backlink}`;
}

export function goToPreviousRoute() {
  const { currentRoute, nextRoutes } = getRegulatoryUpdateRoutes();

  setRegulatoryUpdateRoutes(nextRoutes, currentRoute - 1);
  router.back();
}

export function clearNextRoutes() {
  setRegulatoryUpdateRoutes([], -1);
}

export function isOrchestratorActive(): boolean {
  const { currentRoute, nextRoutes } = getRegulatoryUpdateRoutes();
  return (
    !!nextRoutes && !!nextRoutes.length && currentRoute < nextRoutes.length
  );
}
