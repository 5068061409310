import { RouteConfig } from 'vue-router';
import { Deposit } from '@/modules/cash/views/Deposit';
import { Cashback } from '@/modules/cash/views/Cashback';
import { Costs } from '@/modules/cash/views/Costs';

export const cashRoutes: RouteConfig[] = [
  {
    name: 'costs',
    path: '/user/costs',
    component: Costs,
    meta: {
      context: 'cash'
    }
  },
  {
    path: '/deposit',
    redirect: { name: 'deposit-pix' }
  },
  {
    name: 'deposit-pix',
    path: '/deposit/pix',
    component: Deposit,
    meta: {
      context: 'cash',
      depositMethod: 'pix'
    }
  },
  {
    name: 'deposit-ted',
    path: '/deposit/ted',
    component: Deposit,
    meta: {
      context: 'cash',
      depositMethod: 'ted'
    }
  },
  {
    name: 'cashback',
    path: '/user/cashback',
    component: Cashback,
    meta: {
      context: 'cash'
    }
  }
];
