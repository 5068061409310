
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Chart } from './components/Chart';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ButtonBoxDecide,
  ButtonPrimary,
  SkeletonLoader
} from '@warrenbrasil/nebraska-web';
import { Categories } from '@/types/models/Analytics';
import {
  IDashButtons,
  IDashStatus,
  IHeroBalances
} from '@/modules/trade/services/account';
import { RequestStatus } from '@/modules/trade/store/trade';
import { filtersPortfolioApiIdDependentButtons } from '@/modules/trade/views/TradeView/helpers';

import { Feature } from '@/modules/common/components/Feature';
import { MarketToday } from './components/MarketToday';
import { PageTitle } from './components/PageTitle';

const tradePositionsModule = namespace('tradeModule');
const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    ButtonBoxDecide,
    Chart,
    Feature,
    MarketToday,
    ButtonPrimary,
    SkeletonLoader,
    PageTitle
  }
})
export default class TradeMain extends Vue {
  @CustomerModule.Getter('isRegulatoryStatusBlocked')
  readonly isCustomerBlocked!: boolean;

  @Prop({ type: Object, default: null })
  readonly dashStatus!: IDashStatus | null;

  @Prop({ type: Array, required: false })
  readonly accountButtons?: IDashButtons[];

  @Prop({ type: Object, required: false })
  readonly balances?: IHeroBalances;

  @tradePositionsModule.State('balancesBrokerRequestStatus')
  readonly balancesBrokerRequestStatus!: RequestStatus;

  readonly RequestStatus = RequestStatus;
  readonly Categories = Categories;
  readonly NebraskaColors = NebraskaColors;

  get buttons() {
    const accountButtons = this.accountButtons || [];

    // Se o serviço de buscar o saldo teve erro, a gente não tem o portfolioApiId
    return this.balancesBrokerRequestStatus === RequestStatus.Error
      ? filtersPortfolioApiIdDependentButtons(accountButtons)
      : accountButtons;
  }

  @Emit('open-neologica-modal')
  handleFirstAccessUnifiedAccountModal() {}

  public buttonAction(button: IDashButtons, onClick: Function) {
    return onClick(button.action);
  }

  @Emit('track-trade-dash-buttons-event')
  private handleDashButtonsEvents(dashStatusAction?: string | null) {
    return dashStatusAction;
  }
}
