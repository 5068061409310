import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IFixedIncomeReponse,
  IProductsParams,
  IGovernmentBondReponse,
  IGovernmentBondParams,
  IFundsReponse,
  IProductDetails,
  ICustomOperationsResponse,
  IProductHero,
  IProductDetailsV2
} from './products-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/instruments`
});

export const productsHttp = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/products`
});

export function getFixedIncomeProducts(
  filters: Record<string, string | string[]>,
  params: IProductsParams
): Promise<IFixedIncomeReponse> {
  return http.get(`/v1/catalog/bonds`, {
    params: { ...params, ...filters }
  });
}

export async function getGovernmentBondProducts(
  params: IGovernmentBondParams
): Promise<IGovernmentBondReponse> {
  return await http.get(`/v1/catalog/government-bonds`, {
    params: { ...params }
  });
}

export async function getFundsProducts(
  filters: Record<string, string | string[]>,
  params: IProductsParams
): Promise<IFundsReponse> {
  return await http.get(`/v1/catalog/funds`, {
    params: { ...params, ...filters }
  });
}

export async function getCustomOperationsProducts(
  params: IProductsParams
): Promise<ICustomOperationsResponse> {
  return await http.get(`/v1/catalog/custom-operations`, {
    params: { ...params }
  });
}

export function getProductDetails(
  apiId: string,
  instrumentType: string
): Promise<IProductDetails> {
  return http.get(`/v2/instrument/${apiId}/details`, {
    params: { instrumentType }
  });
}

export async function getProductHero(
  productApiId: string
): Promise<IProductHero> {
  return productsHttp.get(
    `/v3/categories/custom-operations/${productApiId}/hero`
  );
}

export function getProductDetailsV2(
  productApiId: string
): Promise<IProductDetailsV2> {
  return productsHttp.get(
    `/v2/categories/custom-operations/${productApiId}/details`
  );
}
