import { StorageKeys } from '@/modules/common/services/local-storage';
import { isImpersonate } from '@/modules/common/services/partner';
import { CustomerModule } from '@/modules/common/store/CustomerModule';
import { Route } from 'vue-router';

export default function isImpersonateGuard(
  { query }: Route,
  _: Route,
  next: Function
) {
  const isImpersonated = Boolean(query.isImpersonate);

  if (isImpersonated) {
    window.isImpersonateWindow = true;
    CustomerModule.setIsImpersonated(isImpersonate());
    localStorage.setItem(StorageKeys.IsPartnerAccountSession, 'true');
  }

  next();
}
