import { setupWorker } from 'msw';
import { handlers, scenarios } from './handlers';

export const initializeMSW = async () => {
  const isProduction = process.env.NODE_ENV === 'production';
  const isMswEnabled = process.env.VUE_APP_MSW_ENABLED === 'true';

  if (!isProduction) {
    if (isMswEnabled) {
      const scenarioName = new URLSearchParams(
        window.location.hash.split('?')[1] || ''
      ).get('scenario');
      console.log('[MSW Debug] Current scenario:', scenarioName);

      const runtimeScenarios =
        scenarioName && scenarios[scenarioName] ? scenarios[scenarioName] : [];
      console.log('[MSW Debug] Active scenarios:', Object.keys(scenarios));

      const worker = setupWorker(...runtimeScenarios, ...handlers);

      await worker.start({
        serviceWorker: {
          url: '/app/mockServiceWorker.js'
        },
        quiet: true,
        onUnhandledRequest: 'bypass'
      });

      console.log(
        '%c[MSW] Mock Service Worker Enabled 🚀',
        'background: #012089; color: #fff; font-size: 14px; font-weight: bold; padding: 5px 10px; border-radius: 4px; margin: 10px 0;'
      );
    } else {
      console.log(
        '%c[MSW] Mock Service Worker Disabled ⚠️',
        'background: #FFA500; color: #000; font-size: 14px; font-weight: bold; padding: 5px 10px; border-radius: 4px; margin: 10px 0;'
      );
    }
  }
};
