
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EDirection } from '@/modules/transaction/services/unified-account/enums';
import { StatementAvatar } from '@/modules/transaction/components/StatementAvatar';

@Component({
  components: { StatementAvatar }
})
export default class StatementAvatarCell extends Vue {
  @Prop({ type: String, required: true })
  public iconName!: string;

  @Prop({ type: String, required: true })
  private type!: EDirection;

  @Prop({ type: String, default: 'extra-small' })
  readonly size!: string;
}
