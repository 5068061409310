
import Bowser from 'bowser';
import { namespace } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  BaseText,
  ButtonFoundation as Button,
  BaseIcon
} from '@warrenbrasil/nebraska-web';

import {
  getRegulatoryUpdateStatus,
  RegulatoryUpdateStatus
} from '../../services/http/account';
import { WarrenModules } from '@/types/navigation';
import { trackGoToRegulatoryUpdate } from './track-events';

const customerModule = namespace('CustomerModule');

@Component({ components: { BaseText, Button, BaseIcon } })
export default class RegulatoryUpdateWarning extends Vue {
  @Prop({ type: String, default: WarrenModules.Home })
  private cameFrom!: string;

  private status = RegulatoryUpdateStatus.UP_TO_DATE;

  readonly NebraskaColors = NebraskaColors;

  @customerModule.Getter('getCustomerId')
  private customerId!: string;

  @customerModule.Mutation('setRegulatoryUpdateStatus')
  private setRegulatoryUpdateStatus!: (value: RegulatoryUpdateStatus) => void;

  mounted() {
    this.getStatus();
  }

  private async getStatus() {
    try {
      this.status = (await getRegulatoryUpdateStatus(this.customerId)).status;
      this.setRegulatoryUpdateStatus(this.status);
    } catch (e) {
      this.status = RegulatoryUpdateStatus.UP_TO_DATE;
      this.setRegulatoryUpdateStatus(this.status);
    }
  }

  private get isMobile(): boolean {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getPlatformType() !== 'desktop';
  }

  public get textSize(): string {
    return this.isMobile ? 'xs' : 'md';
  }

  public get iconSize(): string {
    return this.isMobile ? '16' : '24';
  }

  public get showWarning(): boolean {
    return this.status !== RegulatoryUpdateStatus.UP_TO_DATE;
  }

  public get statusText(): string {
    if (this.status === RegulatoryUpdateStatus.BLOCKED) {
      return 'Conta bloqueada para investir';
    }

    return 'Atualização cadastral pendente';
  }

  readonly ButtonColors = {
    background: NebraskaColors.statusAlertBackground,
    content: NebraskaColors.statusAlert,
    alphas: {
      default: 1,
      hover: 0.9,
      active: 0.8
    }
  };

  public goToRegulatoryUpdate() {
    trackGoToRegulatoryUpdate();
    this.$router.push({
      name: 'regulatoryUpdateAnnouncement'
    });
  }
}
