
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import {
  Modal,
  HeadingDisplay,
  ContentDisplay,
  ListDisplay,
  ButtonTertiary,
  HelperViewContextual,
  ButtonPrimary
} from '@warrenbrasil/nebraska-web';
import { Portal } from '@/modules/common/components/Portal';

import {
  IVoucherRequest,
  IStatementTransaction
} from '@/modules/transaction/services/unified-account';
import { deleteScheduledTransaction } from '@/modules/transaction/services/banking';
import { notify } from '@/modules/common/helpers/notification';
import { NotificationTypes } from '@/modules/wallet-builder/src/types';

@Component({
  components: {
    Modal,
    HeadingDisplay,
    ContentDisplay,
    ListDisplay,
    ButtonPrimary,
    ButtonTertiary,
    Portal,
    HelperViewContextual
  }
})
export default class ReceiptModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly transaction!: IStatementTransaction;

  @Prop({ type: Object, required: false })
  readonly filters?: IVoucherRequest;

  @Prop({ type: Boolean, default: null })
  readonly isUpcomingTransfer?: boolean;

  @Emit('close')
  close() {
    this.showCancelContent = false;
    this.isLoading = false;
    this.hasError = false;
  }

  @Emit('schedule')
  schedule() {}

  readonly helperViewContent = {
    default: {
      title: 'Cancelar agendamento?',
      description:
        'Seu agendamento será cancelado e o valor vai permanecer na sua conta.',
      icon: 'EF0060'
    },
    error: {
      title: 'Houve um erro ao cancelar seu agendamento',
      description:
        'Não foi possível cancelar seu agendamento. Tente novamente em alguns minutos.',
      icon: 'EF0062'
    }
  };

  isLoading = false;
  hasError = false;
  showCancelContent = false;

  get helperViewMessage() {
    return this.hasError
      ? this.helperViewContent.error
      : this.helperViewContent.default;
  }

  get showScheduledButton() {
    return (
      this.transaction?.canCancel &&
      this.transaction?.direction.includes('Future')
    );
  }

  async cancelSchedule() {
    this.isLoading = true;
    try {
      await deleteScheduledTransaction(this.transaction.id);
      notify({
        group: 'wrn-notifications',
        text: 'Seu agendamento foi cancelado.',
        type: NotificationTypes.success
      });
      this.schedule();
      this.close();
    } catch {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  backDescriptionModal() {
    this.showCancelContent = false;
    this.hasError = false;
  }

  confirmCancelScheduled() {
    this.showCancelContent = true;
  }
}
