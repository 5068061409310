import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { RiskCell } from '@/modules/wealth/components/RiskCell';
import { ProductNameCell } from '@/modules/wealth/components/ProductNameCell';

const productLabel = (products: number) => {
  return products > 1 ? `${products} Produtos` : `${products} Produto`;
};

export const columns = (products: number): DataTableProps.Column[] => {
  return [
    {
      label: productLabel(products),
      component: ProductNameCell,
      width: '24%',
      sort: 'type',
      getter: ({ name, type, color }) => ({
        title: name,
        subtitle: type,
        color
      })
    },
    {
      label: 'Inicio',
      width: '15%',
      align: DataTableProps.Align.Right,
      sort: 'date',
      getter: 'initialDate'
    },
    {
      label: 'Percentual',
      width: '15%',
      align: DataTableProps.Align.Right,
      sort: 'percent',
      getter: ({ percent }) => ({
        text: percent.formatted
      })
    },
    {
      label: 'Saldo bruto',
      width: '15%',
      align: DataTableProps.Align.Right,
      sort: 'balance',
      getter: ({ totalBalance }) => ({
        text: totalBalance.formatted
      })
    },
    {
      label: 'Prazo de resgate',
      width: '17%',
      align: DataTableProps.Align.Right,
      sort: 'withdrawPeriod',
      getter: 'withdrawPeriod'
    },
    {
      label: 'Risco',
      component: RiskCell,
      width: '14%',
      align: DataTableProps.Align.Right,
      sort: 'risk',
      getter: ({ risk }) => ({
        risk
      })
    }
  ];
};
