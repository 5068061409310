import { DataTableProps } from '@warrenbrasil/nebraska-web';
import { RiskCell } from '@/modules/catalog/components';
import { InstrumentNameCell } from '@/modules/trade/components/InstrumentNameCell';

const productLabel = (products: number) => {
  return products > 1 ? `${products} Produtos` : `${products} Produto`;
};

export const columns = (products: number): DataTableProps.Column[] => {
  return [
    {
      label: productLabel(products),
      component: InstrumentNameCell,
      sort: 'BaseAsset.Name',
      width: '25%',
      getter: ({ name, category }) => ({
        symbol: name,
        name: category,
        size: 'small',
        hasEllipsis: true
      })
    },
    {
      label: 'Mínimo inicial',
      align: DataTableProps.Align.Right,
      sort: 'MinimumInvestmentQuantity',
      width: '15%',
      getter: 'minimumInvestment'
    },
    {
      label: 'Ganho máximo',
      align: DataTableProps.Align.Right,
      sort: 'MaximumReturn',
      width: '15%',
      getter: 'maxGain'
    },
    {
      label: 'Risco máximo',
      align: DataTableProps.Align.Right,
      sort: 'MaximumRiskValue',
      width: '15%',
      getter: 'maxRisk'
    },
    {
      label: 'Vencimento',
      align: DataTableProps.Align.Right,
      sort: 'MaturityDate',
      width: '15%',
      getter: 'maturityDate'
    },
    {
      label: 'Risco',
      component: RiskCell,
      align: DataTableProps.Align.Right,
      width: '15%',
      getter: ({ risk }) => ({
        risk
      })
    }
  ];
};
