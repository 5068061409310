import { NavigationGuard } from 'vue-router';

import { CustomerModule } from '@/modules/common/store/CustomerModule';

export const beforeEnterInvestment: NavigationGuard = (_to, _from, next) => {
  const isRegulatoryStatusBlocked = CustomerModule.isRegulatoryStatusBlocked;

  if (isRegulatoryStatusBlocked) {
    return next({ name: 'regulatoryUpdateAnnouncement' });
  }

  next();
};
