import {
  InstrumentWithWatchlist,
  Instrument
} from '@/modules/trade/services/instruments';
import { CustomOperationSummary } from '@/modules/trade/services/custom-operations';
import { NumberFormatted } from '@/modules/trade/types';

export enum RequestStatus {
  Loading = 'loading',
  Error = 'error',
  Default = 'default'
}

export interface TradeState {
  positions: Instrument[];
  positionsUpdatedAt: NumberFormatted;
  watchlist: InstrumentWithWatchlist[];
  customOperations: CustomOperationSummary[];
}
