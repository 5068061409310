import { BaseHttpClient } from '@/modules/common/services/http/__deprecated__/base-http-client';
import { WarrenSession } from '@/modules/common/services/session';
import {
  IExecuteRedemptionRequest,
  IExecuteRedemptionResponse,
  IGetProductsWithdrawResponse,
  IGetTransactionOptionsResponse,
  IGetRedemptionSuggestionsResponse,
  IRedemptionPreviewPayload,
  IRedemptionPreviewResponse,
  IWealthToWithdrawResponse,
  IWealthToWithdrawResponseSuggested,
  IWealthToWithdrawResponseSuggestedPreview,
  IWealthToWithdrawResponseSuggestedSummary,
  ISendPortfolioToWithdrawSuggested,
  IRedemptionWithdrawResponse
} from './withdraw-types';

export class BffWithdraw extends BaseHttpClient {
  constructor() {
    super(undefined, process.env.VUE_APP_BFF_INVESTMENTS_URL);
  }

  public getProductsToWithdrawFromPortfolio = (
    portfolioId: string
  ): Promise<IGetProductsWithdrawResponse> => {
    return this.get(`v2/allocationsToRedeem/${portfolioId}`);
  };

  public getPortfolioTransactionOptions = (
    portfolioId: string,
    customerId: string
  ): Promise<IGetTransactionOptionsResponse> => {
    return this.get('v1/getPortfolioTransactionOptions/', {
      params: {
        portfolioId,
        customerId
      }
    });
  };

  public redemptionPreview = (
    data: IRedemptionPreviewPayload
  ): Promise<IRedemptionPreviewResponse> => {
    return this.post('v2/redemptionPreview/', {
      ...data
    });
  };

  public executeRedemption = (
    data: IExecuteRedemptionRequest
  ): Promise<IExecuteRedemptionResponse> => {
    return this.post('v2/executeRedemption/', data);
  };

  public getRedeemSuggestions =
    (): Promise<IGetRedemptionSuggestionsResponse> => {
      return this.get<IGetRedemptionSuggestionsResponse>(
        'v2/acceptanceListToRedeem'
      );
    };

  public getPortfoliosToWithdraw = (): Promise<IWealthToWithdrawResponse> => {
    const customerId = WarrenSession.get('warren.customerId');
    return this.get<IWealthToWithdrawResponse>(
      `v1/customer/${customerId}/portfoliosToWithdraw`
    );
  };

  public getPortfolioToWithdrawSuggested = (
    portfolioId: string
  ): Promise<IWealthToWithdrawResponseSuggested> => {
    return this.get<IWealthToWithdrawResponseSuggested>(
      `v1/withdrawalFromSuggestedPortfolio/${portfolioId}`
    );
  };

  public getPortfolioToWithdrawSuggestedPreview = (
    portfolioId: string,
    amount: string
  ): Promise<IWealthToWithdrawResponseSuggestedPreview> => {
    return this.get<IWealthToWithdrawResponseSuggestedPreview>(
      `v1/withdrawalRequestedPreview/${portfolioId}?amount=${amount}`
    );
  };

  public getPortfolioToWithdrawSuggestedSummary = (
    portfolioId: string,
    amount: string,
    bankId?: string
  ): Promise<IWealthToWithdrawResponseSuggestedSummary> => {
    return this.get<IWealthToWithdrawResponseSuggestedSummary>(
      `v1/withdrawalRequestedSummary/${portfolioId}?amount=${amount}`,
      {
        params: {
          bankId
        }
      }
    );
  };

  public sendPortfolioToWithdrawSuggested = (
    data: ISendPortfolioToWithdrawSuggested
  ): Promise<IRedemptionWithdrawResponse> => {
    return this.post<IRedemptionWithdrawResponse>(`v1/withdraw`, data);
  };
}
