export interface IOrderFiltersResponse {
  filters: IFilters[];
}

export interface IFilters {
  component: string;
  filterName: string;
  filters: IValueFomatted<string>[];
  parameterName: string;
}

export interface IValueFomatted<T = number, G = string> {
  value: T;
  formatted: G;
}

export interface IFilterFomatted<T = string, G = string> {
  value: T;
  formatted: G;
  selected: boolean;
}

export interface IOrderListResponse {
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  orders: IOrders[];
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface IOrders {
  amount: IValueFomatted;
  apiId: string;
  createdAt: IValueFomatted<string>;
  direction: string;
  expectedFinishAt: IValueFomatted<string>;
  icon: IIcon;
  portfolioName: string;
  productName: string;
  status: string;
  title: string;
  toPortfolioApiId: string;
}

export interface IIcon {
  iconName: string;
  iconColor: string;
  backgroundColor: string;
}

export interface IOrderListRequest {
  pageIndex: number;
  pageSize?: number;
  type?: string;
  period?: string;
  portfolioApiId?: string;
}

export interface IOrdersListRequestFilters {
  period?: string;
  type?: string;
  pageIndex?: number;
  pageSize?: number;
}

export interface IOrdersListResponse {
  count: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  filters: IOrdersListResponseFilters;
  items: IOrders[];
}

export interface IOrdersListResponseFilters {
  [key: string]: IFilterFomatted[];
}

export interface IOrderReceiptV3 {
  apiId: string;
  direction: ITransactionDirection;
  productApiId: string;
  header: string;
  title: string;
  subtitle: string;
  statusMessage: {
    icon: string;
    title: string;
    description: string;
  } | null;
  stepper: {
    title: string | null;
    icon: string | null;
    steps: {
      value: string;
      progress: number;
    }[];
    footer: string | null;
  } | null;
  info: IOrderInfo;
  buttons: IOrderButton[];
}

enum ITransactionDirection {
  REDEMPTION = 'Redemption',
  INVESTMENT = 'Investment'
}

interface IOrderInfo {
  title: string | null;
  items: IInfoItem[];
  buttons: IOrderButton[];
  footer: string | null;
}

interface IInfoItem {
  label: string;
  value: string;
  type: EInfoItemType | EPresentationOrderStatusIconColor;
}

export enum EInfoItemType {
  TEXT = 'text',
  STRIKE = 'strike_through'
}

export enum EPresentationOrderStatusIconColor {
  INFORMATION = 'information',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

interface IOrderButton {
  label: string;
  action: EOrderButtonActions;
}

type EOrderButtonActions =
  | 'product_details'
  | 'cancel_investment'
  | 'cancel_redemption';
