import { IWithdrawDetails } from '@/modules/transaction/services/product-withdraw';

export enum EProductTypes {
  FIXED_INCOME = 'fixed-income',
  GOVERNMENT_BOND = 'government-bond',
  FUNDS = 'funds'
}

export function errorText(
  data: IWithdrawDetails,
  inputValue: number,
  productType: string
) {
  const { minimumWithdraw, productBalance, minimumToStay } = data;

  if (inputValue > productBalance.value) {
    return 'Valor maior que o saldo disponível';
  }

  if (inputValue > 0 && inputValue < minimumWithdraw.value) {
    return 'Valor menor que o mínimo para resgate';
  }

  if (productType === EProductTypes.FUNDS && minimumToStay) {
    if (
      inputValue > 0 &&
      inputValue < productBalance.value &&
      productBalance.value - inputValue < minimumToStay.value
    ) {
      return 'O valor deve respeitar o mínimo de permanência';
    }
  }

  return '';
}

export function errorStatus(
  data: IWithdrawDetails,
  inputValue: number,
  productType: string
) {
  const { minimumWithdraw, productBalance, minimumToStay } = data;

  if (inputValue > productBalance.value) {
    return 'error';
  }

  if (inputValue > 0 && inputValue < minimumWithdraw.value) {
    return 'error';
  }

  if (productType === EProductTypes.FUNDS && minimumToStay) {
    if (
      inputValue > 0 &&
      inputValue < productBalance.value &&
      productBalance.value - inputValue < minimumToStay.value
    ) {
      return 'error';
    }
  }

  return '';
}

export function listDetails(
  data: IWithdrawDetails,
  netBalance: string,
  productType: string,
  loading: boolean
) {
  const { minimumWithdraw, minimumToStay, liquidity } = data;

  const list = [
    {
      label: 'Valor líquido',
      value: netBalance,
      show: true,
      loading
    },
    {
      label: minimumWithdraw.label,
      value: minimumWithdraw.formatted,
      show: Boolean(minimumWithdraw),
      loading: false
    },
    {
      label: minimumToStay.label || '',
      value: minimumToStay.formatted || '',
      show: productType === EProductTypes.FUNDS,
      loading: false
    },
    {
      label: liquidity.label,
      value: liquidity.value,
      show: Boolean(liquidity),
      loading: false
    }
  ];

  return list.filter(item => item.show);
}
