import { render, staticRenderFns } from "./AppliedTable.vue?vue&type=template&id=50c50552&scoped=true&"
import script from "./AppliedTable.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AppliedTable.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./AppliedTable.vue?vue&type=style&index=0&id=50c50552&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c50552",
  null
  
)

export default component.exports