import { httpClient } from '@/modules/common/services/http/http-client';
import { IAccountDataCards } from './types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_CONSOLIDATION_URL}/consolidation/v1/home`
});

export function getButtons(): Promise<IAccountDataCards> {
  return http.get('/buttons/my-account');
}
