
import { Component, Vue, Watch } from 'vue-property-decorator';

import { StockLendingTable } from './components/StockLendingTable';
import { StockLendingFilters } from './components/StockLendingFilters';
import { Pagination } from '@/modules/common/components/Pagination';
import {
  IFilterOption,
  HelperViewStatus,
  HelperViewContextual
} from '@warrenbrasil/nebraska-web';

import { IStockLendingFilters, StockLendingFiltersTypes } from './types';
import { StringFormatted } from '@/modules/trade/types';
import {
  StockLendingPosition,
  getStockLendingList
} from '@/modules/trade/services/instruments';

@Component({
  components: {
    StockLendingTable,
    StockLendingFilters,
    Pagination,
    HelperViewStatus,
    HelperViewContextual
  }
})
export default class StockLending extends Vue {
  filters: IStockLendingFilters = {
    [StockLendingFiltersTypes.Type]: {
      selected: null,
      available: []
    }
  };

  abortSignal: AbortController | null = null;
  isLoading = true;
  error = false;
  itemsPerPage = 10;
  currentPage = 1;
  totalPages = 1;
  positions: StockLendingPosition[] | null = null;
  updatedAt: StringFormatted = {
    value: '-',
    formatted: '-'
  };

  isActive = false;
  totalItems = '';

  get hasPositions() {
    return this.positions && this.positions.length > 0;
  }

  get paidCustodyActive() {
    return this.isActive;
  }

  @Watch('hasPositions', { immediate: true })
  onHasPositionsChange(newValue: boolean) {
    this.$emit('update:hasPositions', newValue);
  }

  @Watch('paidCustodyActive', { immediate: true })
  onPaidCustodyActive(newValue: boolean) {
    this.$emit('update:paidCustodyActive', newValue);
  }

  get hasFilters() {
    return this.filters[StockLendingFiltersTypes.Type].available?.length > 0;
  }

  async created() {
    await this.fetchStockLendingPositions();
  }

  async fetchStockLendingPositions() {
    try {
      this.isLoading = true;
      this.error = false;
      if (this.abortSignal) this.abortSignal.abort();
      this.abortSignal = new AbortController();

      const { items, pagination, updatedAt, totalItems, isActive, filters } =
        await getStockLendingList(
          {
            itemsPerPage: this.itemsPerPage,
            page: this.currentPage,
            side: this.filters[StockLendingFiltersTypes.Type].selected
          },
          this.abortSignal.signal
        );

      this.itemsPerPage = pagination.itemsPerPage;
      this.currentPage = pagination.page;
      this.totalPages = pagination.totalPages;

      this.updatedAt = updatedAt;
      this.totalItems = totalItems.formatted;
      this.isActive = isActive;

      this.positions = items;

      this.filters[StockLendingFiltersTypes.Type].available = filters.available;
      this.filters[StockLendingFiltersTypes.Type].selected = filters.selected;
    } catch {
      this.error = true;
    } finally {
      this.isLoading = false;
    }
  }

  async handleOnFilterChange(
    filterKey: StockLendingFiltersTypes,
    filter: IFilterOption
  ) {
    this.filters[filterKey].selected = filter.value;
    await this.fetchStockLendingPositions();
  }

  async handlePageChange(page: number) {
    this.currentPage = page;
    await this.fetchStockLendingPositions();
  }
}
