import { render, staticRenderFns } from "./PaidCustodyActive.vue?vue&type=template&id=104bbe0d&scoped=true&"
import script from "./PaidCustodyActive.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PaidCustodyActive.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./PaidCustodyActive.vue?vue&type=style&index=0&id=104bbe0d&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104bbe0d",
  null
  
)

export default component.exports