import { httpClient } from '@/modules/common/services/http/http-client';
import { Hiring, PaidCustody, CanceledPaidCustody, FAQ } from './types';

const baseURL = `${process.env.VUE_APP_BFF_URL}/trading`;
export const httpClientWithDefaultOptions = httpClient.withOptions({ baseURL });

export function hiringPaidCustody(): Promise<Hiring> {
  return httpClientWithDefaultOptions.post('/v1/securities-lending');
}

export function getPaidCustody(): Promise<PaidCustody> {
  return httpClientWithDefaultOptions.get('/v1/securities-lending/summary');
}

export function canceledPaidCustody(): Promise<CanceledPaidCustody> {
  return httpClientWithDefaultOptions.put('/v1/securities-lending');
}

export function getFAQ(): Promise<FAQ> {
  return httpClientWithDefaultOptions.get('/v1/securities-lending/faq');
}
