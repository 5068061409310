import { httpClient } from '@/modules/common/services/http/http-client';
import { IRegisterNotificationsData } from './types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/onboarding`
});

export function getNotifications(): Promise<IRegisterNotificationsData> {
  return http.get('/register/notifications');
}
