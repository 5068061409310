
import { Vue, Component, Emit } from 'vue-property-decorator';
import { HelpButton } from '@/modules/trade/components/HelpButton';
import { FooterButton } from './components/FooterButton';

@Component({
  components: {
    HelpButton,
    FooterButton
  }
})
export default class GeneralFooter extends Vue {
  @Emit('help-click')
  public emitHelpClick() {}
}
