import { render, staticRenderFns } from "./Splash.vue?vue&type=template&id=18eba46f&scoped=true&"
import script from "./Splash.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Splash.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Splash.vue?vue&type=style&index=0&id=18eba46f&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18eba46f",
  null
  
)

export default component.exports