import { ReviewStep } from '../components/ReviewStep';
import { InvestmentCompletedStep } from '../components/InvestmentCompletedStep';
import { CustomOperationsSelect } from '../components/SelectStep';
import { CustomOperationsInvestment } from '../components/InvestmentStep';

export enum ECustomOperationProductInvestmentSteps {
  SELECTION,
  INVESTMENT,
  REVIEW,
  CONCLUSION
}

export const ProductInvestmentComponents = {
  [ECustomOperationProductInvestmentSteps.SELECTION]: CustomOperationsSelect,
  [ECustomOperationProductInvestmentSteps.INVESTMENT]:
    CustomOperationsInvestment,
  [ECustomOperationProductInvestmentSteps.REVIEW]: ReviewStep,
  [ECustomOperationProductInvestmentSteps.CONCLUSION]: InvestmentCompletedStep
};
