import { render, staticRenderFns } from "./HomeRegister.vue?vue&type=template&id=3084230f&scoped=true&"
import script from "./HomeRegister.vue?vue&type=script&setup=true&lang=ts&"
export * from "./HomeRegister.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./HomeRegister.vue?vue&type=style&index=0&id=3084230f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3084230f",
  null
  
)

export default component.exports