import { render, staticRenderFns } from "./OrdersV2.vue?vue&type=template&id=16c12aa1&scoped=true&"
import script from "./OrdersV2.vue?vue&type=script&setup=true&lang=ts&"
export * from "./OrdersV2.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./OrdersV2.vue?vue&type=style&index=0&id=16c12aa1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16c12aa1",
  null
  
)

export default component.exports