
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ImpersonateHeader } from '@/modules/common/components/ImpersonateHeader';
import { AppNavbar } from '@/modules/common/components/AppNavbar';
import { MenuList } from '@/modules/common/components/MenuList';

import { WelcomeCurtain } from '@/modules/common/components/WelcomeCurtain';
import { RouterViewWithTransition } from '@/modules/common/components/RouterViewWithTransition';
import { isImpersonate } from '@/modules/common/services/partner';

@Component({
  components: {
    MenuList,
    WelcomeCurtain,
    ImpersonateHeader,
    AppNavbar,
    RouterViewWithTransition
  }
})
export default class AppContainer extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly isAuthenticated!: boolean;

  get isHomePageRoute() {
    return this.$route.meta?.isHomePage;
  }

  get showImpersonateNavbar() {
    return isImpersonate();
  }

  get appContainerClass() {
    return {
      'app-template-container__impersonate': isImpersonate()
    };
  }
}
