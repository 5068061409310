
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseIcon, NavBar, Tab } from '@warrenbrasil/nebraska-web';
import { MenuItem } from './types';
import { namespace } from 'vuex-class';
import { AccountStates } from '@/modules/common/services/http/account';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    BaseIcon,
    NavBar,
    Tab
  }
})
export default class NavbarMenu extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isTransparent?: boolean;

  @Prop({ type: Array, required: true })
  readonly menu!: MenuItem[];

  @CustomerModule.Getter('getAccountStatus')
  readonly accountStatus!: AccountStates;

  @CustomerModule.Getter('isCustomerPJ')
  readonly isCustomerPJ!: boolean;

  isMenuItemActive(item: MenuItem) {
    return this.$route.matched.some(matched => matched.name === item.to);
  }

  isMenuItemDisabled(item: MenuItem) {
    if (item.to === 'home') {
      return false;
    } else {
      return (
        !this.accountStatus?.clientEnabled ||
        !this.accountStatus?.hasInvestmentProfile
      );
    }
  }

  getMenuItemHref(item: MenuItem) {
    return `#/${item.to}`;
  }

  getIcon(item: MenuItem) {
    return this.isMenuItemActive(item)
      ? item.icons.active
      : item.icons.inactive;
  }

  changeTab(item: MenuItem) {
    if (
      item.to === 'home' ||
      this.isCustomerPJ ||
      (this.accountStatus?.hasInvestmentProfile &&
        this.accountStatus.clientEnabled)
    ) {
      this.$emit('tab-select', item.label);
      if (this.$route.name !== item.to) {
        this.$router.push({ name: item.to });
      }
    }
  }
}
